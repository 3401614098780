import { BillingOtherMappingNewDestination } from '@ts/components/BillingOtherMapping';
import { BillingTypes } from '@ts/enums/BillingTypes';

export type Billing = {
  id: number;
  referenceMonth: number;
  originalFilename: string;
  sentAt: string;
  userName: string;
  status: BillingStatus;
  branchName: string;
  fourvisionId: number;
  billedAt: string;
  billingFileId: number;
  md5: string;
  lastEvent: FourvisionEvent;
  pdfPassword: string;
  type: BillingTypes;
  pipeline: BillingTypes;
  isIgnored: number;
};

export type FourvisionEvent = {
  id: number;
  fourvisionId: string;
  message: string;
  status: FourvisionStatus;
  receivedAt: string;
  whenChanged: string;
};

export type BillingMeta = {
  pendingDetail: string;
  pendingReason: string;
  billingFile?: Billing;
};

export type BillingInfo = {
  meta: BillingMeta;
};

export enum BillingStatus {
  received = 'RECEIVED',
  processing = 'PROCESSING',
  invalid = 'INVALID',
  refused = 'REFUSED',
  delayed = 'DELAYED',
  success = 'SUCCESS',
  pending = 'PENDING',
}

export enum FourvisionStatus {
  success = 'SUCCESS',
  invalid = 'INVALID',
  refused = 'REFUSED',
  actionable = 'ACTIONABLE',
  delayed = 'DELAYED',
}

export enum BillingPendingReason {
  missingRequiredData = 'MISSING_REQUIRED_DATA',
  unmappedEntries = 'UNMAPPED_ENTRIES',
  unmappedOthersEntries = 'UNMAPPED_OTHERS_ENTRIES',
  unknownItemKey = 'UNKNOWN_ITEM_KEY',
  unknownHistoryKey = 'UNKNOWN_HISTORY_KEY',
  unmappedHistoryEntries = 'UNMAPPED_HISTORY_ENTRIES',
}

export type BillingMetric = {
  monthYear: string | null;
  refused?: number | null;
  processing?: number | null;
  success?: number | null;
  billed?: number | null;
};

export type BillingOthersMappingDestination = {
  className?: 'BillingOthersMapDestination';
  id: number;
  table: string;
  column: string;
  mappingId: number;
  idx?: number;
  shouldDelete?: boolean;
};

export type BillingOthersMappingListItem = {
  id: number;
  priority: number;
  destinations: Array<BillingOthersMappingDestination>;
  originalFieldName: string;
  type: string;
  createdAt: string;
  pending: boolean;
  companyTradename: string;
  filter: undefined;
  special: string;
};

export type BillingOthersMapping = {
  id: number;
  priority: number;
  table: string;
  column: string;
  ignore: false;
  updatedByName: string;
  createdAt: string;
  updatedAt: string;
  jsonFragment: string;
  companyTradename: string;
  type: string;
  originalFieldName: string;
  udpatedByName: string;
  billingFile?: Billing;
  destinations: Array<BillingOthersMappingDestination>;
  createdByName: string;
  special: string;
  observations: string;
  pdfPassword?: string;
};

export interface AfterMappingInput {
  ignore: boolean;
  priority: number | null;
  special: string | null;
  destinations: Array<
    BillingOthersMappingDestination | BillingOtherMappingNewDestination
  >;
  pipeline: BillingTypes;
}

export interface CreateMappingInput {
  originalFieldName: string | null;
  type: string | null;
  mappingPartType: string;
  priority: number | null;
  destinations: Array<
    BillingOthersMappingDestination | BillingOtherMappingNewDestination
  >;
  pipeline: BillingTypes;
}

export type BillingToRelate = Billing & {
  type: BillingTypes.DANFE | BillingTypes.WATER;
  companyId: number;
  companyName: string;
  branchId: string;
};
