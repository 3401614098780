import {
  AlertOutlined,
  AreaChartOutlined,
  BankOutlined,
  BarChartOutlined,
  CloudDownloadOutlined,
  DashboardOutlined,
  ExclamationCircleOutlined,
  FileSyncOutlined,
  FormOutlined,
  FundViewOutlined,
  LineChartOutlined,
  MonitorOutlined,
  NodeIndexOutlined,
  PieChartOutlined,
  ProfileOutlined,
  SettingOutlined,
  UploadOutlined,
  UserOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import {
  ROUTE_BILLING_COLLECTION,
  ROUTE_BILLING_CONFIGURATION,
  ROUTE_BILLING_RELATION,
  ROUTE_BILLING_UPLOAD,
  ROUTE_BILLING_VIEW,
  ROUTE_BRANCHES,
  ROUTE_COMPANIES,
  ROUTE_CONSUMPTION,
  ROUTE_DEMAND,
  ROUTE_DISTRIBUTION,
  ROUTE_EXPORTING,
  ROUTE_METERS,
  ROUTE_PERIODS,
  ROUTE_POWER_FACTOR,
  ROUTE_STOCK_ACTIONS,
  ROUTE_STOCK_REGISTER,
  ROUTE_USERS,
  ROUTE_WORKING_HOUR,
} from '@constants/routes';
import { KipRole } from '@ts/enums/KipRole';
import { SidebarItemData } from '@ts/layout/sidebar';
import React from 'react';

const getDefaultSidebarItems = () => {
  let startingId = 1;
  return [
    {
      id: startingId++,
      icon: React.createElement(DashboardOutlined),
      title: 'Empresas',
      to: ROUTE_COMPANIES,
      notAllowedRoles: [KipRole.admin, KipRole.user],
    },
    {
      id: startingId++,
      icon: React.createElement(AreaChartOutlined),
      title: 'Análises',
      submenu: [
        {
          id: startingId++,
          icon: React.createElement(BarChartOutlined),
          title: 'Consumo',
          to: ROUTE_CONSUMPTION,
        },
        {
          id: startingId++,
          icon: React.createElement(AreaChartOutlined),
          title: 'Demanda Ativa',
          to: ROUTE_DEMAND,
        },
        {
          id: startingId++,
          icon: React.createElement(LineChartOutlined),
          title: 'Fator de Potência',
          to: ROUTE_POWER_FACTOR,
        },
        {
          id: startingId++,
          icon: React.createElement(PieChartOutlined),
          title: 'Rateio',
          to: ROUTE_DISTRIBUTION,
        },
        {
          id: startingId++,
          icon: React.createElement(PieChartOutlined),
          title: 'Horário Produtivo',
          to: ROUTE_WORKING_HOUR,
        },
        {
          id: startingId++,
          icon: React.createElement(PieChartOutlined),
          title: 'Postos Tarifários',
          to: ROUTE_PERIODS,
        },
      ],
    },
    {
      id: startingId++,
      icon: React.createElement(WifiOutlined),
      title: 'Medidores',
      to: ROUTE_METERS,
    },
    {
      id: startingId++,
      icon: React.createElement(BankOutlined),
      title: 'Unidades',
      to: ROUTE_BRANCHES,
    },
    {
      id: startingId++,
      icon: React.createElement(UserOutlined),
      title: 'Usuários',
      to: ROUTE_USERS,
      notAllowedRoles: [KipRole.user],
    },
    {
      id: startingId++,
      icon: React.createElement(CloudDownloadOutlined),
      title: 'Exportação',
      to: ROUTE_EXPORTING,
    },
    {
      id: startingId++,
      icon: React.createElement(ProfileOutlined),
      title: 'Faturas',
      notAllowedRoles: [],
      submenu: [
        {
          id: startingId++,
          icon: React.createElement(UploadOutlined),
          title: 'Enviar',
          to: ROUTE_BILLING_UPLOAD,
        },
        {
          id: startingId++,
          icon: React.createElement(NodeIndexOutlined),
          title: 'Relacionar',
          to: ROUTE_BILLING_RELATION,
        },
        {
          id: startingId++,
          icon: React.createElement(FundViewOutlined),
          title: 'Visualizar',
          to: ROUTE_BILLING_VIEW,
        },
        {
          id: startingId++,
          icon: React.createElement(FileSyncOutlined),
          title: 'Coleta',
          to: ROUTE_BILLING_COLLECTION,
        },
        {
          id: startingId++,
          icon: React.createElement(SettingOutlined),
          title: 'Configurações',
          to: ROUTE_BILLING_CONFIGURATION,
          notAllowedRoles: [KipRole.user, KipRole.admin],
        },
      ],
    },
    {
      id: startingId++,
      icon: React.createElement(AlertOutlined),
      title: 'Alertas',
      to: '/alertas',
    },
    {
      id: startingId++,
      icon: React.createElement(ExclamationCircleOutlined),
      title: 'Oportunidades',
      to: '/oportunidades',
      submenu: [
        {
          id: startingId++,
          title: 'Faturas',
          icon: '',
          to: '/oportunidades/faturas',
        },
        {
          id: startingId++,
          title: 'Medidores',
          icon: '',
          to: '/oportunidades/medidores',
        },
      ],
    },
    {
      id: startingId++,
      title: 'Acompanhamentos',
      icon: '',
      // TODO: Remove notAllowedRoles when production-ready
      notAllowedRoles: [KipRole.root, KipRole.user, KipRole.admin],
      submenu: [
        {
          id: startingId++,
          title: 'Meus Acompanhamentos',
          icon: React.createElement(MonitorOutlined),
          to: ROUTE_STOCK_ACTIONS,
          // TODO: Remove notAllowedRoles when production-ready
          notAllowedRoles: [KipRole.root, KipRole.user, KipRole.admin],
        },
        {
          id: startingId++,
          title: 'Cadastro de ações',
          icon: React.createElement(FormOutlined),
          to: ROUTE_STOCK_REGISTER,
          // TODO: Remove notAllowedRoles when production-ready
          notAllowedRoles: [KipRole.root, KipRole.user, KipRole.admin],
        },
      ],
    },
  ];
};

export const getFreemiumSidebarItems = (): Array<SidebarItemData> => {
  let startingId = 1;
  return [
    {
      id: startingId++,
      icon: React.createElement(WifiOutlined),
      title: 'Medidores',
    },
    {
      id: startingId++,
      icon: React.createElement(BankOutlined),
      title: 'Unidades',
      to: ROUTE_BRANCHES,
    },
    {
      id: startingId++,
      icon: React.createElement(UserOutlined),
      title: 'Usuários',
      to: ROUTE_USERS,
      notAllowedRoles: [KipRole.user],
    },
    {
      id: startingId++,
      icon: React.createElement(ProfileOutlined),
      title: 'Faturas',
      notAllowedRoles: [],
      submenu: [
        {
          id: startingId++,
          icon: React.createElement(UploadOutlined),
          title: 'Enviar',
          to: ROUTE_BILLING_UPLOAD,
        },
        {
          id: startingId++,
          icon: React.createElement(NodeIndexOutlined),
          title: 'Relacionar',
          to: ROUTE_BILLING_RELATION,
        },
        {
          id: startingId++,
          icon: React.createElement(FundViewOutlined),
          title: 'Visualizar',
          to: ROUTE_BILLING_VIEW,
        },
        {
          id: startingId++,
          icon: React.createElement(FileSyncOutlined),
          title: 'Coleta',
          to: ROUTE_BILLING_COLLECTION,
        },
      ],
    },
    {
      id: startingId++,
      icon: React.createElement(ExclamationCircleOutlined),
      title: 'Oportunidades',
      to: '/oportunidades',
      submenu: [
        {
          id: startingId++,
          title: 'Faturas',
          icon: '',
          to: '/oportunidades/faturas',
        },
      ],
    },
  ];
};

export const DEFAULT_SIDEBAR_ITEMS: Array<SidebarItemData> =
  getDefaultSidebarItems();
