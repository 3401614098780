import { request } from '@services/.';
import {
  PATH_GET_METERS_FROM_BRANCH,
  PATH_GET_METERS_FROM_COMPANY,
  PATH_GET_METERS_FROM_COMPANY_WITH_BRANCHES,
  PATH_METERS,
} from '@constants/api/meter';
import {
  GetListFromCompanyResponse,
  GetListFromCompanyWithBranchesResponse,
  GetListFromMeterResponse,
  MeterResponse,
} from '@ts/api/meter';
import { MeterStatus } from '@ts/enums/MeterStatus';
import { MoveMeterParams } from '@ts/components/MeterPage';
import { MeterFormSchema } from '@ts/forms/meter/Meter';

export class MeterService {
  static async getListFromCompanyIdWithBranches(
    companyId: number
  ): Promise<GetListFromCompanyWithBranchesResponse> {
    return request.get(PATH_GET_METERS_FROM_COMPANY_WITH_BRANCHES, {
      params: { withEggs: 1, searchCompanyId: companyId },
    });
  }

  static getListFromBranch(
    branchId: number,
    status?: MeterStatus | null
  ): Promise<GetListFromCompanyResponse> {
    const searchStatus = status ?? '';
    return request.get(`${PATH_GET_METERS_FROM_BRANCH}`, {
      params: {
        searchBranchId: branchId,
        searchStatus,
      },
    });
  }

  static getListFromCompany(
    companyId: number,
    status?: MeterStatus | null
  ): Promise<GetListFromCompanyResponse> {
    const searchStatus = status ?? '';
    return request.get(`${PATH_GET_METERS_FROM_COMPANY}`, {
      params: {
        searchCompanyId: companyId,
        searchStatus,
      },
    });
  }

  static async getPendingMetersListFromCompanyId(
    companyId: number
  ): Promise<GetListFromMeterResponse> {
    return request.get(PATH_GET_METERS_FROM_BRANCH, {
      params: { searchPending: 1, searchCompanyId: companyId },
    });
  }

  static getMeterById(meterId: number): Promise<MeterResponse> {
    return request.get(`${PATH_GET_METERS_FROM_BRANCH}/${meterId}`);
  }

  static updateMeterById(
    meterId: number,
    formValues: MeterFormSchema
  ): Promise<MeterResponse> {
    return request.put(`${PATH_GET_METERS_FROM_BRANCH}/${meterId}`, {
      ...formValues,
    });
  }

  static moveMeter(
    meterId: number,
    params: MoveMeterParams
  ): Promise<MeterResponse> {
    return request.put(`${PATH_METERS}/${meterId}`, { ...params });
  }
}
