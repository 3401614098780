interface FreemiumBanner {
  title: string;
  image: string;
}

export const DEFAULT_FREEMIUM_BANNERS: FreemiumBanner[] = [
  {
    title: 'Funcionalidade de alertas de telemetria',
    image: '/assets/alertas-banner.png',
  },
  {
    title: 'Funcionalidade de telemetria de entrada',
    image: '/assets/telemetria-banner.png',
  },
  {
    title: 'Funcionalidade de telemetria de submedição',
    image: '/assets/telemetria-banner.png',
  },
  {
    title: 'Funcionalidade de coleta automática de faturas',
    image: '/assets/coleta-automatizada-banner.png',
  },
  {
    title: 'CUBi Copiloto',
    image: '/assets/copiloto-banner.png',
  },
  {
    title: 'Idenfificar e corrigir Reativos',
    image: '/assets/creditos-acabando-banner.png',
  },
  {
    title: 'Extração de dados de Danfes',
    image: '/assets/danfe-banner.png',
  },
  {
    title: 'Extração de dados de faturas de água',
    image: '/assets/agua-banner.png',
  },
  {
    title: 'Oportunidades',
    image: '/assets/oportunidades-banner.png',
  },
];
