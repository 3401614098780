import {
  KipNotificationConfig,
  KipNotificationType,
} from '@ts/utils/KipNotification';
import { notification } from 'antd';

export class KipNotification {
  static success(config: KipNotificationConfig): string {
    return KipNotification.generic(KipNotificationType.success, config);
  }

  static warn(config: KipNotificationConfig): string {
    return KipNotification.generic(KipNotificationType.warn, config);
  }

  static info(config: KipNotificationConfig): string {
    return KipNotification.generic(KipNotificationType.info, config);
  }

  static error(config: KipNotificationConfig): string {
    return KipNotification.generic(KipNotificationType.error, config);
  }

  static defaultError(): string {
    return KipNotification.generic(KipNotificationType.error, {
      title: 'Ops!',
      content: 'Algo deu errado. Por favor, tente novamente.',
    });
  }

  private static generic(
    type: KipNotificationType,
    config: KipNotificationConfig
  ): string {
    const uniqueKey = KipNotification.generateKey();
    notification[type]({
      message: config.title,
      description: config.content,
      duration: config.persistent ? 0 : 5,
      key: uniqueKey,
    });
    return uniqueKey;
  }

  private static generateKey(): string {
    return Math.random().toString(36).substr(2).toUpperCase();
  }
}
