/* eslint-disable no-restricted-syntax */
import {
  DatabaseOutlined,
  DatabaseTwoTone,
  FolderOpenOutlined,
  FolderOpenTwoTone,
  FolderOutlined,
  FolderTwoTone,
  HomeOutlined,
  HomeTwoTone,
} from '@ant-design/icons';
import {
  KipDataNode,
  KipDataNodeKey,
  KipMeterTreeBranch,
  KipMeterTreeData,
  KipMeterTreeEntityName,
  KipMeterTreeGenericEntity,
  KipMeterTreeGrouping,
  KipMeterTreeMeter,
} from '@ts/components/MeterTreeData';

const generateKeyForTreeEntity = (
  entity: KipMeterTreeGenericEntity,
  pending?: boolean
) => {
  if (pending) {
    return `${entity.className}-${entity.id}-pending`;
  }

  return `${entity.className}-${entity.id}`;
};

export function meterToDataNode(meter: KipMeterTreeMeter): KipDataNode {
  return {
    title: meter.name,
    icon: ({ selected }: { selected: boolean }) =>
      selected ? <DatabaseTwoTone /> : <DatabaseOutlined />,
    key: generateKeyForTreeEntity(meter),
    id: meter.id,
    type: KipMeterTreeEntityName.egg,
  };
}

export function pendingMeterToDataNode(meter: KipMeterTreeMeter): KipDataNode {
  return {
    title: meter.name,
    icon: ({ selected }: { selected: boolean }) =>
      selected ? <DatabaseTwoTone /> : <DatabaseOutlined />,
    key: generateKeyForTreeEntity(meter, true),
    id: meter.id,
    type: KipMeterTreeEntityName.egg,
  };
}

function groupingToDataNode(
  grouping: KipMeterTreeGrouping,
  onlyMetersEnable = false
): KipDataNode {
  const { eggs, groupings, name, id } = grouping;

  const notPendingEggs = eggs.filter((egg) => egg.pending === false);

  const convertedMeters: Array<KipDataNode> =
    notPendingEggs.map(meterToDataNode);

  const convertedChildrenGroupings: Array<KipDataNode> = groupings.map(
    (g: KipMeterTreeGrouping): KipDataNode =>
      groupingToDataNode(g, onlyMetersEnable)
  );

  const children: Array<KipDataNode> = convertedMeters.concat(
    convertedChildrenGroupings
  );

  return {
    title: name,
    icon: ({
      expanded,
      selected,
    }: {
      expanded: boolean;
      selected: boolean;
    }) => {
      if (expanded && selected) return <FolderOpenTwoTone />;
      if (expanded && !selected) return <FolderOpenOutlined />;
      if (!expanded && selected) return <FolderTwoTone />;
      return <FolderOutlined />;
    },
    disabled: onlyMetersEnable,
    key: generateKeyForTreeEntity(grouping),
    children,
    type: KipMeterTreeEntityName.grouping,
    id,
  };
}

function branchToDataNode(
  branch: KipMeterTreeBranch,
  onlyMetersEnable = false
): KipDataNode {
  const { groupings, eggs, tradename, id } = branch;
  const convertedGroupings: Array<KipDataNode> = groupings.map(
    (g: KipMeterTreeGrouping): KipDataNode =>
      groupingToDataNode(g, onlyMetersEnable)
  );

  const notPendingEggs = eggs.filter((egg) => egg.pending === false);

  const convertedMeters: Array<KipDataNode> =
    notPendingEggs.map(meterToDataNode);

  const children: Array<KipDataNode> =
    convertedGroupings.concat(convertedMeters);

  return {
    title: tradename,
    icon: ({ selected }: { selected: boolean }) =>
      selected ? <HomeTwoTone /> : <HomeOutlined />,
    key: generateKeyForTreeEntity(branch),
    children,
    disabled: onlyMetersEnable,
    type: KipMeterTreeEntityName.branch,
    id,
  };
}

export function branchesToDataNodes(
  meterTree: KipMeterTreeData,
  onlyMetersEnable = false
): Array<KipDataNode> {
  if (!meterTree) {
    return [];
  }
  const dataNodes: Array<KipDataNode> = meterTree.map(
    (meter: KipMeterTreeBranch): KipDataNode =>
      branchToDataNode(meter, onlyMetersEnable)
  );
  return dataNodes;
}

function getAllKeysInsideNode(node: KipDataNode): Array<KipDataNodeKey> {
  return !node.children
    ? []
    : node.children.reduce(
        (
          acc: Array<KipDataNodeKey>,
          cur: KipDataNode
        ): Array<KipDataNodeKey> => {
          const { key } = cur;
          const hasMoreChildren = !!cur.children;
          return hasMoreChildren
            ? [...acc, key, ...getAllKeysInsideNode(cur)]
            : [...acc, key];
        },
        []
      );
}

function getAllNodesInsideNode(node: KipDataNode): Array<KipDataNode> {
  return !node.children
    ? []
    : node.children
        .reduce(
          (acc: Array<KipDataNode>, cur: KipDataNode): Array<KipDataNode> => {
            const hasMoreChildren = !!cur.children;
            return hasMoreChildren
              ? [...acc, cur, ...getAllNodesInsideNode(cur)]
              : [...acc, cur];
          },
          []
        )
        .map((_cur: KipDataNode) => {
          const cur = { ..._cur };
          delete cur.children;
          return cur;
        });
}

function findNodeInNode(
  node: KipDataNode,
  targetKey: string
): KipDataNode | null {
  if (node.key === targetKey) {
    return node;
  }
  if (node.children) {
    let result = null;
    for (let i = 0; result === null && i < node.children.length; i++) {
      result = findNodeInNode(node.children[i], targetKey);
    }
    return result;
  }
  return null;
}

function findNodeInNodeArray(
  nodes: Array<KipDataNode>,
  targetKey: string
): KipDataNode | null {
  let result = null;
  for (let i = 0; result === null && i < nodes.length; i++) {
    result = findNodeInNode(nodes[i], targetKey);
  }
  return result;
}

export function getChildrenKeysFromNodeKey(
  nodes: Array<KipDataNode>,
  target: string
): Array<KipDataNodeKey> {
  const node: KipDataNode | null = findNodeInNodeArray(nodes, target);
  if (!node) return [];
  return getAllKeysInsideNode(node);
}

export function getChildrenNodesFromNodeKey(
  nodes: Array<KipDataNode>,
  target: string
): Array<KipDataNode> {
  const node: KipDataNode | null = findNodeInNodeArray(nodes, target);
  if (!node) return [];
  return getAllNodesInsideNode(node);
}

export function getNodesKeys(nodes: Array<KipDataNode>): Array<KipDataNodeKey> {
  return nodes.reduce(
    (acc: Array<KipDataNodeKey>, cur: KipDataNode): Array<KipDataNodeKey> => [
      ...acc,
      ...getAllKeysInsideNode(cur),
      cur.key,
    ],
    []
  );
}

export function recursivelyFilterNode(
  node: KipDataNode,
  searchCondition: Function
): KipDataNode | null {
  const hasMatchingChild = node.children?.some((child: KipDataNode) =>
    recursivelyFilterNode(child, searchCondition)
  );

  if (!searchCondition(node) && !hasMatchingChild) {
    return null;
  }

  if (searchCondition(node) && !hasMatchingChild) {
    return node;
  }

  const filteredNode: KipDataNode = { ...node };
  filteredNode.children = [];

  if (hasMatchingChild) {
    const children = node.children as Array<KipDataNode>;
    filteredNode.children = children
      .map((child) => recursivelyFilterNode(child, searchCondition))
      .filter((child) => !!child) as Array<KipDataNode>;
  }

  return filteredNode;
}

export function filterNodeArrayBySearchLabel(
  nodes: Array<KipDataNode>,
  search: string
): Array<KipDataNode> {
  const searchCondition = (node: KipDataNode) =>
    `${node.title}`.toLowerCase().includes(search.toLowerCase());

  const filteredNodes: Array<KipDataNode> = [];

  for (const node of nodes) {
    const filteredNode = recursivelyFilterNode(node, searchCondition);
    if (filteredNode) {
      filteredNodes.push(filteredNode);
    }
  }

  return filteredNodes;
}

export function changeNodesDisabledStatesByKeys(
  _nodes: Array<KipDataNode>,
  keys: Array<KipDataNodeKey>,
  value: boolean
): Array<KipDataNode> {
  const nodes = Array.from(_nodes);
  for (let i = 0; i < nodes.length; i++) {
    for (let j = 0; j < keys.length; j++) {
      const node = findNodeInNode(nodes[i], keys[j]);
      if (node) {
        node.disabled = value;
      }
    }
  }
  return nodes;
}

export const enableKeys = (
  nodes: Array<KipDataNode>,
  keys: Array<KipDataNodeKey>
): Array<KipDataNode> => changeNodesDisabledStatesByKeys(nodes, keys, false);

export const disableKeys = (
  nodes: Array<KipDataNode>,
  keys: Array<KipDataNodeKey>
): Array<KipDataNode> => changeNodesDisabledStatesByKeys(nodes, keys, true);

export const changeStateByLimit = (
  _node: Array<KipDataNode>,
  keys: Array<KipDataNodeKey>,
  value: boolean
) => {
  const node = Array.from(_node);
  for (let i = 0; i < node.length; i++) {
    if (!keys.includes(node[i].key)) {
      node[i].disabled = value;
      const children = node[i]?.children;
      if (children) {
        node[i].children = changeStateByLimit(children, keys, value);
      }
    }
  }
  return node;
};
