import { Address } from './Address';
import { Branch } from './Branch';
import { Contact } from './Contact';
import type { User } from './User';

export type Company = {
  id?: 1;
  cnae?: string;
  cnpj?: string;
  department?: string;
  employees?: number;
  name?: string;
  photo?: string;
  subDepartment?: string;
  tradename?: string;
  website?: string;
  users?: Array<User>;
  timezone?: string;
  address?: Address;
  contact?: Contact;
  branches?: Array<Branch>;
  totalBranches?: number;
  premium?: number;
  permissionCollectionBilling: boolean;
};

export const EMBEDS_DATA_KEY = 'quicksightConfigs';

export type LoginResponseCompany = Company & {
  [EMBEDS_DATA_KEY]?: Array<EmbeddedPageData>;
};

export type EmbeddedPageData = {
  id: number;
  displayName: string;
};
