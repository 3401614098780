import { BugReportFormSchema } from '@ts/forms/bugReport';
import { Button, Form, Row, Input } from 'antd';
import React, { FC, useState } from 'react';

const { useForm } = Form;
const FormItem = Form.Item;
const { TextArea } = Input;

type BugReportFormProps = {
  onFinish: (data: BugReportFormSchema) => Promise<void>;
};

export const BugReportForm: FC<BugReportFormProps> = ({ onFinish }) => {
  const [form] = useForm<BugReportFormSchema>();
  const [loading, setLoading] = useState(false);

  const detailsRules = [
    {
      required: true,
      message: 'Por favor detalhe o problema!',
    },
  ];

  const submitHandle = async (data: BugReportFormSchema) => {
    setLoading(true);
    if (onFinish) await onFinish(data);
    setLoading(false);
  };

  return (
    <Form
      name="bugReport"
      onFinish={submitHandle}
      form={form}
      layout="vertical"
    >
      <FormItem
        rules={detailsRules}
        name="details"
        label="Qual o comportamento você vai reportar?"
      >
        <TextArea
          placeholder="Escreva detalhadamente sobre o problema"
          autoSize={{ minRows: 4, maxRows: 4 }}
          showCount
          style={{ marginBottom: 10 }}
          maxLength={280}
        />
      </FormItem>

      <Row justify="end" style={{ width: '100%' }}>
        <FormItem noStyle>
          <Button
            loading={loading}
            shape="round"
            type="primary"
            htmlType="submit"
          >
            Enviar
          </Button>
        </FormItem>
      </Row>
    </Form>
  );
};
