import {
  ArrowUpOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { Alert, Button, Divider, List, Modal } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useAuth } from '@contexts/Auth';
import axios from 'axios';
import { mappingStatusFromStatusPage } from '@constants/components/mappingStatusFromStatusPage';
import styles from './styles.module.less';

const MODAL_WIDTH = 490;
const PATH_STATUS = 'https://cubienergia.statuspage.io/api/v2/status.json';
const STATUS_URL = 'https://cubienergia.statuspage.io/';

const accessData = [
  {
    url: 'https://cubienergia.notion.site/Base-de-Conhecimento-b040b91cd37d42b3847893753a273e3d',
    title: 'Guia da plataforma',
  },
];

const contactData = [
  {
    title: 'Suporte',
    email: 'suporte@cubienergia.com',
    number: '11964974429',
  },
  {
    title: 'Comercial',
    email: 'upsell@cubienergia.com',
    number: '',
  },
];

type HelpModalProps = {
  modalState: boolean;
  onClose: () => void;
};

export const HelpModal: FC<HelpModalProps> = ({ modalState, onClose }) => {
  const { backendTag, frontendVersion } = useAuth();
  const [status, setStatus] = useState<string>('');
  const fetchStatus = useCallback(async () => {
    const response = await axios.get(PATH_STATUS);

    if (response) {
      setStatus(response.data.status.description);
    }
  }, []);

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  return (
    <Modal
      visible={modalState}
      width={MODAL_WIDTH}
      onCancel={onClose}
      bodyStyle={{ padding: '10px 25px 20px 25px' }}
      title="Ajuda"
      destroyOnClose
      footer={
        <Button type="primary" onClick={onClose}>
          OK
        </Button>
      }
    >
      <List
        header={<b>Acesse:</b>}
        dataSource={accessData}
        size="small"
        renderItem={(item) => (
          <List.Item>
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              {item.title}
              <ArrowUpOutlined
                style={{ fontSize: '10px', marginLeft: '5px' }}
                rotate={45}
              />
            </a>
          </List.Item>
        )}
      />
      <List
        header={<b>Contato:</b>}
        dataSource={contactData}
        size="small"
        renderItem={(item) => (
          <List.Item>
            <b className={styles.contactTitle}>{item.title}</b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href={`mailto:${item.email}`} className={styles.link}>
                <MailOutlined style={{ marginRight: '5px' }} />
                {item.email}
              </a>
              {item.number && (
                <>
                  <Divider type="vertical" />
                  <a
                    href={`https://wa.me/55${item.number}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    <WhatsAppOutlined
                      style={{ marginRight: '5px', color: '#52c41a' }}
                    />
                    {item.number}
                  </a>
                </>
              )}
            </div>
          </List.Item>
        )}
      />
      <List header={<b>Status:</b>} size="small">
        <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Alert
            message={mappingStatusFromStatusPage[status]?.message}
            type={mappingStatusFromStatusPage[status]?.type}
            showIcon
          />
          <a
            href={STATUS_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Mais detalhes
            <ArrowUpOutlined
              style={{ fontSize: '10px', marginLeft: '5px' }}
              rotate={45}
            />
          </a>
        </List.Item>
      </List>
      <List header={<b>Versão do sistema:</b>} size="small" bordered={false}>
        <List.Item>
          <b>Frontend</b>
          <span>{frontendVersion}</span>
        </List.Item>
        <List.Item>
          <b>Backend</b>
          <span>{backendTag}</span>
        </List.Item>
      </List>
    </Modal>
  );
};
