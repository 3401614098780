import { RangePickerInput } from '@ts/inputs/RangePickerInput';
import dayjs from 'dayjs';
import { subtractHour, subtractDay } from './subtractDates';

// 15 min -> últimas 6 horas
// 1 hora -> últimas 24 horas
// 1 dia -> últimos 7 dias
// 1 mês -> últimos 3 meses

export const handleFifteenMinutesScale = (): RangePickerInput => {
  const endDate = dayjs(); // now
  const startDate = subtractHour(endDate, 6); // last 6 hours
  return [startDate, endDate];
};

export const handleOneHourScale = (): RangePickerInput => {
  const endDate = dayjs(); // now
  const startDate = subtractHour(endDate, 24); // last 1 day
  return [startDate, endDate];
};

export const handleOneDayScale = (): RangePickerInput => {
  const endDate = dayjs(); // now
  const startDate = subtractDay(endDate, 7); // last 1 week
  return [startDate, endDate];
};

export const handleOneMonthScale = (): RangePickerInput => {
  const endDate = dayjs().endOf('month'); // now
  const startDate = subtractDay(endDate, 3 * 4 * 7).startOf('month'); // last 3 months
  return [startDate, endDate];
};
