import MaskedInput, { conformToMask } from 'react-text-mask';
import { BaseSyntheticEvent, FC, PropsWithChildren } from 'react';
import { InputProps } from 'antd';
import {
  TELEPHONE_MASK_ELEVEN_NUMBERS,
  TELEPHONE_MASK_TEN_NUMBERS,
} from './masks';

type TelephoneNumberInputProps = PropsWithChildren<InputProps>;

export const TelephoneNumberInput: FC<TelephoneNumberInputProps> = ({
  onChange,
  value = '',
  placeholder,
  autoComplete,
}: TelephoneNumberInputProps) => {
  const handleChange = (e: BaseSyntheticEvent) => {
    const onlyNumbers = e.target.value.replace(/\(|\)|-|\s|_/gi, '');
    if (onChange) onChange(onlyNumbers);
  };

  const isLongerThanTen = value && value?.toString().length >= 11;
  const mask = !isLongerThanTen
    ? TELEPHONE_MASK_TEN_NUMBERS
    : TELEPHONE_MASK_ELEVEN_NUMBERS;

  const newValue = conformToMask(`${value}`, mask, {
    guide: false,
  }).conformedValue;

  return (
    <MaskedInput
      onChange={handleChange}
      className="ant-input"
      value={newValue}
      mask={(vl: string) => {
        const onlyNumbers = vl.replace(/\(|\)|-|\s|_/gi, '');
        if (onlyNumbers.length < 11) {
          return TELEPHONE_MASK_TEN_NUMBERS;
        }
        return TELEPHONE_MASK_ELEVEN_NUMBERS;
      }}
      placeholder={placeholder}
      autoComplete={autoComplete}
    />
  );
};
