import { Divider, Space } from 'antd';
import { FC } from 'react';
import styles from './styles.module.less';

type EnvTagsProps = {
  frontend: string;
  backend: string;
  env: string;
  collapsed: boolean;
};

export const EnvTags: FC<EnvTagsProps> = ({
  backend,
  frontend,
  env,
  collapsed,
}) => (
  <div className={styles.container}>
    <Divider className={styles.divider} />
    <Space className={styles.envTags} size={1} direction="vertical">
      <p>{env}</p>
      {!collapsed ? (
        <>
          <p>b-{backend}</p>
          <p>f-{frontend}</p>
        </>
      ) : null}
    </Space>
  </div>
);
