import { BASE_URL } from '@constants/api';
import { HEADER_BYPASS_DATA_INTERCEPTOR } from '@constants/api/headers';
import { handleRequestError } from '@utils/interceptors/handleRequestError';
import axios, { AxiosResponse } from 'axios';

export const request = axios.create({
  baseURL: BASE_URL,
  validateStatus: () => true, // not to throw exceptions on non-200 statuses
  // withCredentials: true, // send cookies in every request
});

// (1) returns data directly from successful responses
// (2) call error handler and returns empty object for unsuccessful
//     responses to avoid type errors along the app
request.interceptors.response.use((res: AxiosResponse) => {
  const bypass = res.config.headers[HEADER_BYPASS_DATA_INTERCEPTOR];
  if (bypass) return res;
  const successful: boolean = res.status >= 200 && res.status < 300;
  if (successful) return res?.data;
  handleRequestError(res);
  return { success: false, httpStatus: res.status, resObject: res };
});
