import {
  ALLOWED_IMAGE_TYPES_LIST,
  DIMENSIONS_ERROR_MESSAGE,
  FILE_SIZE_ERROR_MESSAGE,
  FILE_TYPE_ERROR_MESSAGE,
  MAX_VALID_HEIGHT,
  MAX_VALID_WIDTH,
} from '@constants/components/uploadLogoForm';
import { message } from 'antd';

const validateFileType = (file: File): boolean => {
  if (!ALLOWED_IMAGE_TYPES_LIST.includes(file.type)) {
    message.error(FILE_TYPE_ERROR_MESSAGE);
    return false;
  }
  return true;
};

export const validateFileSize = (file: File): boolean => {
  const isSizeOk = file.size / 1024 / 1024 <= 1;
  if (!isSizeOk) {
    message.error(FILE_SIZE_ERROR_MESSAGE);
    return false;
  }
  return true;
};

export const validateFileDimensions = async (file: File): Promise<boolean> =>
  new Promise<boolean>((resolve) => {
    const imageProps = new Image();
    imageProps.src = window.URL.createObjectURL(file);
    imageProps.onload = () => {
      const { width, height } = imageProps;
      if (!(width <= MAX_VALID_WIDTH) || !(height <= MAX_VALID_HEIGHT)) {
        message.error(DIMENSIONS_ERROR_MESSAGE(width, height), 8);
        resolve(false);
      }
      resolve(true);
    };
  });

export const postCropValidate = async (file: File): Promise<boolean> => {
  const dimensionsOk = await validateFileDimensions(file);
  return dimensionsOk;
};

export const preCropValidate = (file: File): boolean => {
  const sizeOk = validateFileSize(file);
  const typeOk = validateFileType(file);
  return sizeOk && typeOk;
};
