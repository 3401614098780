import { NpsFormSchema } from '@ts/forms/nps/npsFormSchema';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Row, Radio, Alert } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import { createSequentialArray } from '@utils/helpers/createSequentialArray';
import dayjs from 'dayjs';

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

type NpsFormProps = {
  onFinish: (data: NpsFormSchema) => Promise<void>;
  onCancel?: Function;
};

const { useForm } = Form;
const FormItem = Form.Item;

export const NpsForm: FC<NpsFormProps> = ({ onFinish, onCancel }) => {
  const [form] = useForm<NpsFormSchema>();
  const [score, setScore] = useState<number | null>(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleFinish = useCallback(
    async (data: NpsFormSchema) => {
      setSubmitLoading(true);
      if (onFinish) await onFinish(data);
      setSubmitLoading(false);
    },
    [onFinish]
  );

  const handleReminderLaterClick = (): void => {
    window.localStorage.setItem('lastNpsReminder', dayjs().toISOString());
    if (onCancel) onCancel();
  };

  const scoreRules: Array<Rule> = useMemo(
    (): Array<Rule> => [
      {
        required: true,
        message: 'Insira uma pontuação!',
      },
    ],
    []
  );

  const feedbackRules: Array<Rule> = useMemo((): Array<Rule> => {
    if (score !== null && score <= 6) {
      return [
        {
          required: true,
          message:
            'Por favor, descreva a experiência que levou você a dar essa nota.',
        },
        {
          min: 10,
          message: 'A mensagem deve ter pelo menos 10 caracteres.',
        },
      ];
    }
    return [];
  }, [score]);

  useEffect(() => {
    if (score !== null) {
      if (score > 6) {
        form.resetFields(['cons']);
      }
      form.isFieldsValidating(['cons']);
    }
  }, [score, form]);

  return (
    <Form name="NPS" layout="vertical" form={form} onFinish={handleFinish}>
      <Alert
        message=""
        description="Este popup não aparecerá pelos próximos 3 meses após o preenchimento e envio do formulário!"
        type="info"
        style={{ marginBottom: 15 }}
      />
      <FormItem
        name="score"
        label="Qual é a probabilidade de você recomendar a CUBi a um(a) colega?"
        rules={scoreRules}
      >
        <RadioGroup
          buttonStyle="solid"
          name="score"
          onChange={(e) => setScore(e.target.value)}
        >
          {createSequentialArray(11).map((elem) => (
            <RadioButton key={`radio${elem}`} value={elem}>
              {elem}
            </RadioButton>
          ))}
        </RadioGroup>
      </FormItem>
      <FormItem name="pros" label="O que a CUBi tem de melhor?">
        <Input placeholder="Quais são os pontos fortes da CUBi?" />
      </FormItem>
      <FormItem
        name="cons"
        label="O que a CUBi pode melhorar?"
        rules={feedbackRules}
      >
        <Input placeholder="Sugestões, reclamações..." />
      </FormItem>
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Row>
          <Button shape="round" type="ghost" onClick={handleReminderLaterClick}>
            Responder mais tarde
          </Button>
        </Row>
        <Row>
          <FormItem noStyle>
            {onCancel && (
              <Button
                shape="round"
                type="ghost"
                onClick={() => onCancel()}
                style={{ marginRight: 10 }}
              >
                Cancelar
              </Button>
            )}
            <Button
              loading={submitLoading}
              shape="round"
              type="primary"
              htmlType="submit"
            >
              Enviar
            </Button>
          </FormItem>
        </Row>
      </Row>
    </Form>
  );
};
