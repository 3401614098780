import { MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Button, Modal, ModalProps } from 'antd';
import React, { ReactNode } from 'react';

export type FreemiumModalProps = {
  title: string;
  message: string | ReactNode;
  banner?: string;
} & ModalProps;

export const FreemiumModal: React.FC<FreemiumModalProps> = ({
  title,
  message,
  banner,
  ...props
}) => {
  const footerButtons = banner
    ? null
    : [
        <p key="talktous">Entre em contato conosco, </p>,
        <Button
          icon={<WhatsAppOutlined />}
          key="wpp"
          type="primary"
          style={{ backgroundColor: '#25d366' }}
          href="http://wa.me/5511955569930?text=Olá%20gostaria%20de%20saber%20mais%20sobre%20os%20planos%20premium%20do%20kip!"
          target="_blank"
          title="WhatsApp"
        >
          WhatsApp
        </Button>,
        <Button
          icon={<MailOutlined />}
          href="mailto:comercial@cubienergia.com"
          key="mailto"
          type="primary"
          title="Email"
        >
          E-mail
        </Button>,
      ];

  const width = banner ? 800 : undefined;

  const style = banner ? { padding: 0, margin: 0 } : undefined;

  const DEFAULT_LANDING_PAGE_LINK =
    'https://conteudo.cubienergia.com/funcionalidades-adicionais-cubikip-freemium';

  return (
    <Modal
      centered
      visible
      footer={footerButtons}
      width={width}
      title={title}
      style={style}
      bodyStyle={style}
      {...props}
    >
      {banner && (
        <a
          href={DEFAULT_LANDING_PAGE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={banner}
            alt="promotion-banner"
            style={{ width: '100%', marginBottom: '1em' }}
          />
        </a>
      )}
      {message ? <p style={{ padding: '0px 2em' }}>{message}</p> : null}
    </Modal>
  );
};
