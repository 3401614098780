import { Rule } from 'antd/lib/form';

export const oldPassRules: Array<Rule> = [
  {
    required: true,
    message: 'Insira a senha atual!',
  },
];

export const newPassRules: Array<Rule> = [
  {
    min: 12,
    message: 'Sua nova senha deve conter no mínimo 12 dígitos!',
  },
  {
    required: true,
    message: 'Insira a nova senha!',
  },
];

export const confirmNewPassRules: Array<Rule> = [
  {
    required: true,
    message: 'Confirme sua nova senha!',
  },
  ({ getFieldValue }) => ({
    validator: (_, value) => {
      const newPass = getFieldValue('newPass');
      if (newPass !== value) {
        return Promise.reject(new Error('Senha não coincide!'));
      }
      return Promise.resolve();
    },
  }),
];
