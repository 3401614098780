import { KipScale } from '@ts/enums/KipScale';
import { RangePickerInput } from '@ts/inputs/RangePickerInput';
import {
  handleFifteenMinutesScale,
  handleOneDayScale,
  handleOneHourScale,
  handleOneMonthScale,
} from './scaleHandlers';

export const getIntervalByScale = (scale: KipScale): RangePickerInput => {
  switch (scale) {
    case KipScale.fifteenMinutes:
      return handleFifteenMinutesScale();
    case KipScale.oneHour:
      return handleOneHourScale();
    case KipScale.oneDay:
      return handleOneDayScale();
    default:
      return handleOneMonthScale();
  }
};
