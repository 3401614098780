import { SidebarItemData } from '@ts/layout/sidebar';
import { Menu } from 'antd';
import Link from 'next/link';

interface Props {
  data: SidebarItemData;
}

export const SidebarItem = ({ data, ...rest }: Props) => {
  const { icon, title, submenu, to, id } = data;

  return submenu ? (
    <Menu.SubMenu key={id} icon={icon} title={title} {...rest}>
      {submenu.map((i) => (
        <SidebarItem data={i} key={i.id} />
      ))}
    </Menu.SubMenu>
  ) : (
    <Menu.Item key={id} icon={icon} {...rest}>
      <Link href={to ?? ''} key={id}>
        <a href={to ?? ''}>{title}</a>
      </Link>
    </Menu.Item>
  );
};
