import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { HintVideoService } from '@services/hintVideo';
import { HintVideo } from '@ts/entities/HintVideo';
import { Button, Modal } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';

interface HintVideoData {
  types: string[];
  videoHints: HintVideo[];
  currentVideoHint: HintVideo | null;
}

export const HintVideoButton: React.FC = () => {
  const [videoHintData, setVideoHintData] = useState<HintVideoData>({
    types: [],
    videoHints: [],
    currentVideoHint: null,
  });

  const [modalIsVisible, setModalIsVisible] = useState(false);

  const router = useRouter();
  const path = router.asPath;

  const fetchVideoHint = async () => {
    const res = await HintVideoService.getAllVideos();

    if (res && res.success) {
      const videoHints = res.data;
      const types = Array.from(new Set(videoHints.map((hint) => hint.url)));

      const currentEmbedVideo = videoHints.find((hint) => hint.url === path);

      setVideoHintData({
        types,
        videoHints,
        currentVideoHint: currentEmbedVideo ?? null,
      });
    }
  };

  useEffect(() => {
    fetchVideoHint();
  }, [router]);

  const showVideoHint = useMemo(
    () => videoHintData.types.includes(path),
    [path, videoHintData]
  );

  const renderButton = () => (
    <Button
      icon={<VideoCameraOutlined />}
      style={{ marginRight: 10 }}
      type="link"
      onClick={() => setModalIsVisible(true)}
    >
      Dicas de uso
    </Button>
  );

  const renderModalTitle = (title: string) => (
    <p style={{ fontSize: '1.5em', margin: 0 }}>{title}</p>
  );

  const onCloseModal = () => setModalIsVisible(false);

  const renderModal = (currentVideo: HintVideo | null) => {
    if (!currentVideo) return null;
    return (
      <Modal
        title={renderModalTitle(currentVideo.title)}
        width={930}
        onCancel={onCloseModal}
        visible={modalIsVisible}
        footer={null}
        destroyOnClose
      >
        <div>
          <iframe
            width="860"
            height="480"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded hint video"
            src={`${currentVideo.embedLink}?hideEmbedTopBar=true`}
          />
        </div>
      </Modal>
    );
  };

  return (
    <>
      {showVideoHint ? renderButton() : null}
      {showVideoHint ? renderModal(videoHintData.currentVideoHint) : null}
    </>
  );
};
