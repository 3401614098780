import { useAuth } from '@contexts/Auth';
import { ChangePasswordFormSchema } from '@ts/forms/user/ChangePassword';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Button, Col, Form, Input, Row } from 'antd';
import { FC, useState } from 'react';
import { useProfileForms } from '@hooks/useProfileForms';
import { confirmNewPassRules, newPassRules, oldPassRules } from './rules';

const FormItem = Form.Item;

interface ChangePasswordFormProps {
  onCancel?: Function;
  afterFinish?: Function;
}

export const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  onCancel,
  afterFinish,
}: ChangePasswordFormProps) => {
  const { user } = useAuth();
  const [form] = Form.useForm<ChangePasswordFormSchema>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const initialValues: Partial<ChangePasswordFormSchema> = {
    id: user.id,
  };

  const { handleChangePasswordFinish } = useProfileForms();

  const handleFinish = async (values: ChangePasswordFormSchema) => {
    setSubmitLoading(true);
    await handleChangePasswordFinish(values);
    if (afterFinish) await afterFinish(values);
    setSubmitLoading(false);
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  return (
    <Form
      name="ChangePassword"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={handleFinish}
    >
      <FormItem name="id" style={{ display: 'none' }}>
        <Input disabled />
      </FormItem>
      <FormItem label="Senha Atual" name="oldPass" rules={oldPassRules}>
        <Input placeholder="Senha Atual" type="password" required />
      </FormItem>
      <FormItem
        label="Nova Senha"
        style={{
          marginBottom: 0,
        }}
        name="newPass"
        rules={newPassRules}
      >
        <Input placeholder="Nova Senha" type="password" required />
      </FormItem>
      <FormItem shouldUpdate>
        {() => (
          <PasswordStrengthBar
            password={form.getFieldValue('newPass')}
            minLength={12}
            shortScoreWord="Senha muito curta"
            scoreWords={['Fraca', 'Fraca', 'Média', 'Boa', 'Forte']}
          />
        )}
      </FormItem>
      <FormItem
        label="Confirmar Nova Senha"
        name="confirmNewPass"
        rules={confirmNewPassRules}
      >
        <Input placeholder="Confirmar Nova Senha" type="password" required />
      </FormItem>
      <Row justify="end" gutter={20}>
        <Col>
          <Button onClick={() => handleCancel()}>Cancelar</Button>
        </Col>
        <Col>
          <Button loading={submitLoading} htmlType="submit" type="primary">
            Confirmar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
