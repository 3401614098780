import AntdConfigProvider, {
  ConfigProviderProps,
} from 'antd/lib/config-provider';
import { createContext, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ptBr from 'antd/lib/locale/pt_BR';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/pt-br';

type AntdConfigProviderProps = ConfigProviderProps;

export interface ConfigContextProps {}

export const ConfigContext = createContext({} as ConfigContextProps);

export const useConfig = () => useContext(ConfigContext);

// TODO change app font

export const ConfigProvider: React.FC = ({ children }) => {
  useEffect(() => {
    dayjs.extend(utc);
    dayjs.locale('pt-br');
    dayjs.extend(relativeTimePlugin);
  }, []);

  const antdConfig: AntdConfigProviderProps = {
    locale: ptBr,
    direction: 'ltr',
  };

  const data: ConfigContextProps = {};

  return (
    <ConfigContext.Provider value={data}>
      <AntdConfigProvider {...antdConfig}>{children}</AntdConfigProvider>
    </ConfigContext.Provider>
  );
};
