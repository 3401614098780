import { UserService } from '@services/user';
import { Rule, RuleObject } from 'antd/lib/form';

export const nameRules: Array<Rule> = [
  { required: true, message: 'Insira o nome!' },
];

export const emailRules: Array<Rule> = [
  { required: true, message: 'Insira o email!' },
  { type: 'email', message: 'Insira um email válido!' },
  // based on https://codesandbox.io/s/80wvb?file=/index.js:3093-3409
  // TODO show spinner while validating + show success label when validated
  // TODO turn this into an independent component
  ({ getFieldValue }) => ({
    validator: async (_: RuleObject, value: string): Promise<void> => {
      const currentUserId = getFieldValue('id');
      const response = await UserService.userAvailable({ email: value }, [
        currentUserId,
      ]);
      const emailAvailable = Boolean(response?.success);
      if (emailAvailable) return Promise.resolve();
      return Promise.reject(new Error('Este email já está em uso.'));
    },
  }),
];

export const phoneRules: Array<Rule> = [
  {
    required: true,
    validator: (rule, value, callback) => {
      if (!value) {
        callback('Preencha o telefone de contato!');
      }
      if (value && value.length < 10) {
        callback('Preencha um telefone de contato válido!');
      }
      callback();
    },
  },
];

export const positionRules: Array<Rule> = [
  {
    required: true,
    message: 'Insira o cargo!',
  },
];
