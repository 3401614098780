import { PATH_COMPANY, PATH_COMPANY_NAME } from '@constants/api/company';
import { request } from '@services/.';
import { GetAllResponse, GetCompanyResponse } from '@ts/api/company';
import { KipResponse } from '@ts/api/KipResponse';
import { CreateCompanyFormSchema } from '@ts/forms/company/Create';
import { CompanyGeneralInformationFormSchema } from '@ts/forms/company/GeneralInformation';
import { ENTRIES_PER_PAGE } from '@constants/settings/pagination';
import { UploadPhotoInput } from '@ts/api/branch';

export class CompanyService {
  static async getAll(page?: number, params?: any): Promise<GetAllResponse> {
    if (typeof page === 'undefined') {
      return request.get(PATH_COMPANY);
    }
    return request.get(PATH_COMPANY, {
      params: {
        paginationStart: ENTRIES_PER_PAGE * (page - 1),
        paginationNumber: ENTRIES_PER_PAGE,
        ...params,
      },
    });
  }

  static async getCompanyName(): Promise<GetAllResponse> {
    return request.get(PATH_COMPANY_NAME);
  }

  static async getCompany(id: number): Promise<GetCompanyResponse> {
    return request.get(`${PATH_COMPANY}/${id}`);
  }

  static async create(data: CreateCompanyFormSchema): Promise<KipResponse> {
    return request.post(`${PATH_COMPANY}`, data);
  }

  static async updateGeneralInfo(
    data: CompanyGeneralInformationFormSchema | UploadPhotoInput
  ): Promise<KipResponse> {
    return request.put(`${PATH_COMPANY}/${data.id}`, data);
  }

  static async updatePhoto(base64: string): Promise<KipResponse> {
    return request.put(`${PATH_COMPANY}`, { photo: base64 });
  }

  static async delete(id: number): Promise<KipResponse> {
    return request.delete(`${PATH_COMPANY}/${id}`);
  }
}
