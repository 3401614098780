import { QuestionOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Dispatch, FC, SetStateAction } from 'react';

type HelpButtonProps = {
  setHelpModalState: Dispatch<SetStateAction<boolean>>;
};

export const HelpButton: FC<HelpButtonProps> = ({ setHelpModalState }) => (
  <Button
    style={{ position: 'fixed', bottom: '2%', right: '1%' }}
    shape="circle"
    size="large"
    onClick={() => setHelpModalState(true)}
    icon={<QuestionOutlined />}
  />
);
