import { useAuth } from '@contexts/Auth';
import { UserService } from '@services/user';
import { ChangePasswordFormSchema } from '@ts/forms/user/ChangePassword';
import { EditProfileFormSchema } from '@ts/forms/user/EditProfile';
import { log } from '@utils/KipLog';
import { KipNotification } from '@utils/KipNotification';

type ProfileFormsHook = {
  handleEditProfileFinish: (values: EditProfileFormSchema) => Promise<void>;
  handleChangePasswordFinish: (
    values: ChangePasswordFormSchema
  ) => Promise<void>;
};

// TODO should this code be in the forms themselves?
export const useProfileForms = (): ProfileFormsHook => {
  const { updateUser } = useAuth();

  const handleEditProfileFinish = async (values: EditProfileFormSchema) => {
    log.info('Submitting edit profile form...', { values });
    const res = await UserService.editProfile(values);
    if (!res?.success) {
      log.warn('Edit profile form submission failed', { values, res });
      return;
    }
    log.info('Edit profile form submission successful', { values, res });
    await updateUser();
    KipNotification.success({ title: 'Dados alterados!' });
  };

  const handleChangePasswordFinish = async (
    values: ChangePasswordFormSchema
  ) => {
    log.info('Submitting change password form', { values });
    const res = await UserService.changePassword(values);
    if (!res?.success) {
      log.warn('Change password form submission failed', { values, res });
      return;
    }
    log.info('Change password form submission successful', { values, res });
    KipNotification.success({ title: 'Senha alterada!' });
  };

  const data: ProfileFormsHook = {
    handleEditProfileFinish,
    handleChangePasswordFinish,
  };

  return data;
};
