export const ALLOWED_IMAGE_TYPES_LIST = ['image/jpeg', 'image/png'];

export const FILE_SIZE_ERROR_MESSAGE = 'A imagem deve ter no máximo 1MB!';

export const DEFAULT_UPLOAD_ERROR_MESSAGE =
  'Não foi possível fazer upload. Por favor, tente novamente.';

export const FILE_TYPE_ERROR_MESSAGE =
  'O formato da imagem deve ser .JPEG ou .PNG';

export const MAX_VALID_HEIGHT = 490;
export const MAX_VALID_WIDTH = 490;

export const DIMENSIONS_ERROR_MESSAGE = (width: number, height: number) =>
  `Atenção, a imagem deve ter no máximo ${MAX_VALID_WIDTH}x${MAX_VALID_HEIGHT} pixels. (A imagem enviada possui ${width}x${height} pixels)`;
