import { AlertService } from '@services/alerts';
import {
  AlertConfig,
  AlertLog,
  AlertNotification,
  NewAlertConfig,
} from '@ts/api/alerts';
import { KipResponse } from '@ts/api/KipResponse';
import { PaginatedData } from '@ts/api/PaginatedData';
import { EditAlertFormSchema } from '@ts/forms/alert';
import { KipNotification } from '@utils/KipNotification';

export const useAlerts = () => {
  const fetchAlertsLog = async (
    params?: {
      searchConfigName?: string;
      searchMeterName?: string;
      searchOccurrenceDate?: string;
      searchBranchName?: string;
    },
    currentPage: number = 1
  ): Promise<PaginatedData<AlertLog> | false> => {
    const response = await AlertService.getAlertsLog(params, currentPage);

    if (!response || !response?.success || !response?.data) {
      KipNotification.error({
        title: 'Não foi possível receber o log de alertas',
      });
      return false;
    }

    return response.data;
  };

  const deleteAlertLog = async (
    log: AlertLog
  ): Promise<KipResponse<AlertLog> | false> => {
    const response = await AlertService.deleteAlertLog(log.id);

    if (!response || !response?.success) {
      KipNotification.error({
        title: `Não foi possível deletar o log ${log.name}`,
      });
      return;
    }

    KipNotification.success({
      title: `Log ${log.name} deletada com sucesso!`,
    });
  };

  const fetchAlertsConfigurations = async (
    params?: {
      searchName?: string;
      searchType?: string;
      searchCreatedAt?: string;
      searchCompanyId?: number;
      searchBranchIds?: string;
    },
    currentPage: number = 1
  ): Promise<PaginatedData<AlertConfig> | false> => {
    const response = await AlertService.getAlertsConfigurations(
      params,
      currentPage
    );

    if (!response || !response?.success || !response?.data) {
      KipNotification.error({
        title: 'Não foi possível receber as configurações de alertas',
      });
      return false;
    }

    return response.data;
  };

  const fetchAlertConfigurationById = async (
    id: number
  ): Promise<AlertConfig | false> => {
    const response = await AlertService.getAlertConfigurationById(id);

    if (!response || !response?.success || !response?.data) {
      KipNotification.error({
        title: 'Não foi possível receber os dados deste alerta',
      });
      return false;
    }

    return response.data;
  };

  const fetchAlertNotifications = async (args?: {
    searchMessage?: string;
    searchBranchName?: string;
    searchType?: string;
    searchCreatedAt?: string;
    searchUnread?: boolean;
  }): Promise<PaginatedData<AlertNotification> | false> => {
    const response = await AlertService.getKipNotifications(args);

    if (!response || !response?.success || !response?.data) {
      KipNotification.error({
        title: 'Não foi possível receber a lista notificações',
      });
      return false;
    }

    return response.data;
  };

  const deleteAlertConfiguration = async (
    notification: AlertConfig
  ): Promise<KipResponse<AlertConfig> | false> => {
    const response = await AlertService.deleteAlertConfiguration(
      notification.id
    );

    if (!response || !response?.success || !response?.data) {
      KipNotification.error({
        title: `Não foi possível deletar o alerta ${notification.name}`,
      });
      return;
    }

    KipNotification.success({
      title: `Configuração ${notification.name} deletada com sucesso!`,
    });
  };

  const deleteAlertNotifications = async (
    notificationIds: number[]
  ): Promise<KipResponse<AlertNotification>> => {
    const response = await AlertService.deleteKipNotification(notificationIds);

    if (!response || !response?.success) {
      KipNotification.error({
        title: `Não foi possível deletar as notificações`,
      });
      return;
    }

    if (response?.details?.fail) {
      response?.details?.fail.map((an: AlertNotification): any => {
        KipNotification.error({
          title: `Não foi possível deletar o alerta ${an.message}`,
        });
        return true;
      });
    }
  };

  const updateNotificationStatus = async (notificationIds: number[]) => {
    const response = await AlertService.updateKipNotification(notificationIds);

    if (!response || !response?.success) {
      KipNotification.error({
        title: `Não foi possível alterar status das notificações`,
      });
      return;
    }

    if (response?.details?.fail) {
      response?.details?.fail.map((an: AlertNotification): any => {
        KipNotification.error({
          title: `Não foi possível deletar o alerta ${an.message}`,
        });
        return true;
      });
    }
  };

  const pauseAlert = async (id: number): Promise<void> => {
    const response = await AlertService.pauseAlert(id);

    if (!response || !response?.success || !response?.data) {
      KipNotification.error({
        title: 'Não foi possível pausar o alerta',
      });
    }
  };

  const resumeAlert = async (id: number): Promise<void> => {
    const response = await AlertService.resumeAlert(id);

    if (!response || !response?.success || !response?.data) {
      KipNotification.error({
        title: 'Não foi possível ativar o alerta',
      });
    }
  };

  const createAlert = async (newAlert: NewAlertConfig) => {
    const response = await AlertService.createAlert(newAlert);

    if (!response || !response?.success || !response?.data) {
      KipNotification.error({
        title: 'Não foi possível criar o alerta',
      });
      return false;
    }

    KipNotification.success({
      title: 'Alerta criado com sucesso!',
    });
    return true;
  };

  const updateAlert = async (id: number, payload: EditAlertFormSchema) => {
    const response = await AlertService.updateAlertConfiguration(id, payload);

    if (!response || !response?.success || !response?.data) {
      KipNotification.error({
        title: 'Não foi possível editar o alerta',
      });
      return false;
    }

    KipNotification.success({
      title: 'Alerta editado com sucesso!',
    });
    return true;
  };

  return {
    fetchAlertsLog,
    fetchAlertsConfigurations,
    fetchAlertConfigurationById,
    fetchAlertNotifications,
    deleteAlertConfiguration,
    deleteAlertNotifications,
    deleteAlertLog,
    updateNotificationStatus,
    pauseAlert,
    resumeAlert,
    createAlert,
    updateAlert,
  };
};
