import { SidebarItemData, SubmenuItemData } from '@ts/layout/sidebar';

export const getSidebarSelectedKeys = (
  route: string,
  sidebarItems: Array<SidebarItemData>
): string[] =>
  sidebarItems
    .reduce((acc: number[], sid: SidebarItemData) => {
      const currentBaseRoute = `/${route.split('/')[1].split('?')[0]}`;

      const selected = sid.to ? currentBaseRoute === sid.to : false;

      const submenusSelected = sid.submenu?.reduce(
        (subAcc: number[], sub: SubmenuItemData) => {
          const subSelected = sub.to ? route === sub.to : false;
          if (subSelected) return [...subAcc, sub.id];
          return subAcc;
        },
        []
      );

      if (submenusSelected) return [...acc, sid.id, ...submenusSelected];
      if (selected) return [...acc, sid.id];
      return acc;
    }, [])
    .map((i: number) => i.toString());
