import { AlertService } from '@services/alerts';
import { AlertNotification } from '@ts/api/alerts';
import { Card, List, Typography } from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import styles from './styles.module.less';

interface AlertListProps {
  notifications: Array<AlertNotification>;
  fetchNotifications: () => Promise<void>;
  loading: boolean;
}

const ListItem = List.Item;
const ListItemMeta = List.Item.Meta;
const { Text } = Typography;

export const AlertList: React.FC<AlertListProps> = ({
  notifications,
  fetchNotifications,
  loading,
}) => {
  const onClickBadge = async (id: number) => {
    const res = await AlertService.updateKipNotification([id]);

    if (!res) {
      return;
    }

    await fetchNotifications();
  };

  const limitedNotificationByTheFirst99 = useMemo(
    () => notifications.slice(0, 99),
    [notifications]
  );
  return (
    <div>
      <Card bordered={false} bodyStyle={{ padding: 0 }} loading={false}>
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={limitedNotificationByTheFirst99}
          locale={{ emptyText: 'Não há novas notificações' }}
          renderItem={(alert: AlertNotification, index: number) => (
            <div className={styles.containerMask}>
              <ListItem onClick={() => onClickBadge(alert.id)}>
                <ListItemMeta
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                  title={
                    <Text>
                      {index + 1}. {alert.message}
                    </Text>
                  }
                  description={`${dayjs(alert.sendedAt).fromNow()} - ${
                    alert.branchName
                  }`}
                />
              </ListItem>
            </div>
          )}
        />
      </Card>
    </div>
  );
};
