import { NpsForm } from '@components/Forms/NPS';
import { useAuth } from '@contexts/Auth';
import Modal from 'antd/lib/modal/Modal';
import { FC, useEffect, useState } from 'react';

export const NpsPrompt: FC = () => {
  const { promptNps, handleNpsFinish, handleNpsCancel } = useAuth();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Small delay before showing modal (for UX reasons only)
    if (promptNps) setTimeout(() => setShowModal(true), 1000);
    else setShowModal(false);
  }, [promptNps]);

  return (
    <Modal
      visible={showModal}
      title="Pesquisa de satisfação"
      footer={null}
      onCancel={handleNpsCancel}
      keyboard
      destroyOnClose
    >
      <NpsForm onFinish={handleNpsFinish} onCancel={handleNpsCancel} />
    </Modal>
  );
};
