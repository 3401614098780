import { FC, useState } from 'react';
import { BugOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import { BugReportForm } from '@components/Forms/BugReport';
import { BugReportFormSchema } from '@ts/forms/bugReport';
import { BugReportService } from '@services/bugReport';
import { KipNotification } from '@utils/KipNotification';
import styles from './styles.module.less';

// TODO: Remover constante após release de alertas
const BUG_REPORT_RELEASED = false;

type BugReportProps = {
  collapsed: boolean;
};

export const BugReport: FC<BugReportProps> = ({ collapsed }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const buttonText = 'Reportar bug';

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const renderButton = () => (
    <Button
      className={styles.bugReportButton}
      onClick={openModal}
      icon={<BugOutlined />}
      type="text"
      block
    >
      {collapsed ? '' : buttonText}
    </Button>
  );

  const onFinishHandle = async (data: BugReportFormSchema): Promise<void> => {
    const res = await BugReportService.sendBugReport(data);
    if (res && !res.success) {
      KipNotification.error({ title: 'Não foi possível enviar' });
      return;
    }
    KipNotification.success({ title: 'Enviado com sucesso!' });
    closeModal();
  };

  return BUG_REPORT_RELEASED ? (
    <div className={!collapsed ? styles.bugReportContainer : undefined}>
      {collapsed ? (
        <Tooltip placement="right" title={buttonText}>
          {renderButton()}
        </Tooltip>
      ) : (
        renderButton()
      )}
      <Modal
        onCancel={closeModal}
        visible={isModalVisible}
        footer={null}
        destroyOnClose
      >
        <BugReportForm onFinish={onFinishHandle} />
      </Modal>
    </div>
  ) : (
    <></>
  );
};
