import {
  BILLING_PATHS,
  PATH_BILLING_SOURCE_COUNT,
  PATH_BILLING_JSON_ID,
  PATH_BILLING_MAPPING_TABLE,
  PATH_BILLING_MAPPING_TABLE_AFTERMAPPING,
  PATH_BILLING_OTHERS_MAP,
  PATH_BILLING_SOURCE,
  PATH_BILLING_SOURCE_METRICS,
  PATH_BILLING_OTHERS_MAP_AFTERMAPPING,
} from '@constants/api/billing';
import {
  HEADER_BYPASS_DATA_INTERCEPTOR,
  NORMALIZED_HEADER_CONTENT_DISPOSITION,
} from '@constants/api/headers';
import { ENTRIES_PER_PAGE } from '@constants/settings/pagination';
import {
  BillingCountResponse,
  DeleteBillingResponse,
  GetAllBillingsResponse,
  GetBillingInfo,
  GetBillingMetricsResponse,
  GetOtherMappingOptionsResponse,
  GetOthersMappingListResponse,
  GetOthersMappingResponse,
  RestoreBillingResponse,
  RunBillingParseResponse,
  UploadBillingsResponse,
} from '@ts/api/billing';
import { KipResponse } from '@ts/api/KipResponse';
import { BillingOtherMappingNewDestination } from '@ts/components/BillingOtherMapping';
import {
  AfterMappingInput,
  BillingOthersMappingDestination,
  BillingStatus,
  CreateMappingInput,
} from '@ts/entities/Billing';
import { BillingTypes } from '@ts/enums/BillingTypes';
import { log } from '@utils/KipLog';
import { KipNotification } from '@utils/KipNotification';
import downloadjs from 'downloadjs';
import { request } from '.';

export class BillingService {
  static async uploadBillings(data: FormData): Promise<UploadBillingsResponse> {
    return request.post(`${PATH_BILLING_SOURCE}`, data);
  }

  static async getAllBillings(
    page: number = 1,
    params?: any
  ): Promise<GetAllBillingsResponse> {
    return request.get(PATH_BILLING_SOURCE, {
      params: {
        paginationStart: ENTRIES_PER_PAGE * (page - 1),
        paginationNumber: ENTRIES_PER_PAGE,
        ...params,
      },
    });
  }

  static async getBillingsToRelate(
    page: number = 1,
    params?: any
  ): Promise<GetAllBillingsResponse> {
    return request.get(PATH_BILLING_SOURCE, {
      params: {
        paginationStart: ENTRIES_PER_PAGE * (page - 1),
        paginationNumber: ENTRIES_PER_PAGE,
        ...params,
        searchBillingType: params?.searchBillingType || [
          BillingTypes.WATER,
          BillingTypes.DANFE,
        ],
        searchStatus:
          params?.searchStatus ||
          Object.values(BillingStatus).filter(
            (status) => status !== BillingStatus.processing
          ),
      },
    });
  }

  static async relateBilling(
    id: number,
    branchIds: string | Array<string>,
    type: BillingTypes.DANFE | BillingTypes.WATER
  ): Promise<KipResponse> {
    const payload =
      type === BillingTypes.DANFE ? branchIds : { branchId: branchIds };
    return request.put(`${BILLING_PATHS[type].RELATE}/${id}`, payload);
  }

  static async getBranchesRelated(
    id: number,
    type: BillingTypes.DANFE
  ): Promise<KipResponse<Array<number>>> {
    return request.get(`${BILLING_PATHS[type].INFO}/${id}/branches`);
  }

  static async getBillingInfo(
    id: number,
    type: BillingTypes
  ): Promise<GetBillingInfo> {
    return request.get(`${BILLING_PATHS[type].INFO}/${id}`);
  }

  static async runBillingParse(
    id: number,
    type: BillingTypes,
    isBillingFile: boolean = false
  ): Promise<RunBillingParseResponse> {
    return request.post(BILLING_PATHS[type].PARSE, {
      id,
      isBillingFile: isBillingFile ?? undefined,
    });
  }

  static async getOthersMapList(
    params: any
  ): Promise<GetOthersMappingListResponse> {
    return request.get(PATH_BILLING_OTHERS_MAP, {
      params: {
        paginationStart: undefined,
        paginationNumber: undefined,
        ...params,
      },
    });
  }

  static async getMappingById(
    id: number,
    params: any
  ): Promise<GetOthersMappingResponse> {
    return request.get(`${PATH_BILLING_OTHERS_MAP}/${id}`, {
      ...params,
    });
  }

  static async getMappingTableOptions(
    type: BillingTypes = BillingTypes.ENERGY
  ): Promise<GetOtherMappingOptionsResponse> {
    return request.get(PATH_BILLING_MAPPING_TABLE, {
      params: { searchBillingType: type },
    });
  }

  static async getMappingColumnOptions(
    table: string,
    type: BillingTypes = BillingTypes.ENERGY
  ): Promise<GetOtherMappingOptionsResponse> {
    return request.get(`${PATH_BILLING_MAPPING_TABLE}/${table}`, {
      params: { searchBillingType: type },
    });
  }

  static async getMappingTableOptionsAfterMapping(
    type: BillingTypes = BillingTypes.ENERGY
  ): Promise<GetOtherMappingOptionsResponse> {
    return request.get(PATH_BILLING_MAPPING_TABLE_AFTERMAPPING, {
      params: { searchBillingType: type },
    });
  }

  static async getMappingColumnOptionsAfterMapping(
    table: string,
    type: BillingTypes
  ): Promise<GetOtherMappingOptionsResponse> {
    return request.get(`${PATH_BILLING_MAPPING_TABLE_AFTERMAPPING}/${table}`, {
      params: { searchBillingType: type },
    });
  }

  static async undoMapping(
    id: number
  ): Promise<GetOtherMappingOptionsResponse> {
    return request.put(`${PATH_BILLING_OTHERS_MAP}/${id}/undo`);
  }

  static async getMetrics(
    type: BillingTypes
  ): Promise<GetBillingMetricsResponse> {
    return request.get(PATH_BILLING_SOURCE_METRICS, {
      params: { searchBillingType: type },
    });
  }

  static async downloadBillingFromBillingFile(
    billingFileId: number
  ): Promise<boolean> {
    const res = await request.get(`${PATH_BILLING_SOURCE}/${billingFileId}`, {
      responseType: 'blob',
      timeout: 30000,
      headers: {
        [HEADER_BYPASS_DATA_INTERCEPTOR]: true,
      },
    });

    if (res.status !== 200) {
      log.error(`Failed to download billing. HTTP status: ${res.status}`, {
        res,
        billingFileId,
      });
      KipNotification.error({
        title: `Não foi possível fazer download da fatura`,
      });
      return false;
    }

    // TODO hard-coded while waiting for backend fix
    // const mime: string | undefined = res.headers[HEADER_CONTENT_TYPE];

    const filename: string | undefined = res.headers[
      NORMALIZED_HEADER_CONTENT_DISPOSITION
    ]?.split('filename=')[1]
      .replace(/"/g, '')
      .trim();
    const { data } = res;

    const mime = 'application/pdf';

    if (!mime || !filename || !data) {
      log.error('Received incomplete data from billing download', {
        billingFileId,
        mime,
        filename,
        data,
        res,
      });
      return false;
    }

    KipNotification.success({
      title: `Transferindo a fatura...`,
    });

    const pdf = new Blob([res.data], { type: mime });

    const pdfURL = URL.createObjectURL(pdf);

    window.open(pdfURL);

    return true;
  }

  static async downloadBillingJson(billingFileId: number): Promise<boolean> {
    const res = await request.get(`${PATH_BILLING_JSON_ID}/${billingFileId}`, {
      responseType: 'blob',
      timeout: 30000,
      headers: {
        [HEADER_BYPASS_DATA_INTERCEPTOR]: true,
      },
    });

    if (res.status !== 200) {
      log.error(`Failed to download billing. HTTP status: ${res.status}`, {
        res,
        billingFileId,
      });
      KipNotification.error({
        title: `Não foi possível fazer download do json`,
      });
      return false;
    }

    // TODO hard-coded while waiting for backend fix
    // const mime: string | undefined = res.headers[HEADER_CONTENT_TYPE];

    const filename: string | undefined = res.headers[
      NORMALIZED_HEADER_CONTENT_DISPOSITION
    ]?.split('filename=')[1]
      .replace(/"/g, '')
      .trim();
    const { data } = res;

    const mime = 'application/json';

    if (!mime || !filename || !data) {
      log.error('Received incomplete data from billing json download', {
        billingFileId,
        mime,
        filename,
        data,
        res,
      });
      return false;
    }

    KipNotification.success({
      title: `Transferindo o json...`,
    });

    const fileNameWithoutMime = filename.split('.')[0];

    downloadjs(data, fileNameWithoutMime, mime);

    return true;
  }

  static async deleteBilling(
    id: number,
    type: BillingTypes
  ): Promise<DeleteBillingResponse> {
    return request.delete(`${BILLING_PATHS[type].DEFAULT}/${id}`);
  }

  static async restoreBilling(
    id: number,
    type: BillingTypes
  ): Promise<RestoreBillingResponse> {
    return request.post(`${BILLING_PATHS[type].RESTORE}/${id}`);
  }

  static async deleteMapping(id: number): Promise<GetOthersMappingResponse> {
    return request.delete(`${PATH_BILLING_OTHERS_MAP}/${id}`);
  }

  static async updateMapping(
    id: number,
    infos: {
      destinations: Array<
        BillingOthersMappingDestination | BillingOtherMappingNewDestination
      >;
      ignore: boolean | null;
      priority: number | null;
      special: string | null;
      observations: string | null;
    }
  ): Promise<GetOthersMappingResponse> {
    return request.put(`${PATH_BILLING_OTHERS_MAP}/${id}`, infos);
  }

  static async createAfterMapping(
    input: AfterMappingInput
  ): Promise<GetOthersMappingResponse> {
    return request.post(`${PATH_BILLING_OTHERS_MAP_AFTERMAPPING}`, input);
  }

  static async createMapping(
    input: CreateMappingInput
  ): Promise<GetOthersMappingResponse> {
    return request.post(`${PATH_BILLING_OTHERS_MAP}`, input);
  }

  static async getBillingCount(): Promise<BillingCountResponse> {
    return request.get(PATH_BILLING_SOURCE_COUNT);
  }
}
