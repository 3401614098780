import { AlertType } from '@components/Layout/BillingDetails';

type StatusType = {
  type: AlertType;
  message: string;
};

export const mappingStatusFromStatusPage: { [key: string]: StatusType } = {
  'All Systems Operational': {
    type: 'success',
    message: 'Todos os sistemas estão operacionais',
  },
  'Major System Outage': {
    type: 'error',
    message: 'Grande interrupção do sistema',
  },
  'Partial System Outage': {
    type: 'warning',
    message: 'Interrupção parcial do sistema',
  },
  'Minor Service Outage': {
    type: 'warning',
    message: 'Pequena interrupção de serviço ',
  },
  'Degraded System Service': {
    type: 'warning',
    message: 'Serviço de sistema degradado',
  },
  'Partially Degraded Service': {
    type: 'warning',
    message: 'Serviço Parcialmente Degradado',
  },
  'Service Under Maintenance': {
    type: 'info',
    message: 'Serviço em manutenção',
  },
};
