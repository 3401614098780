import {
  PATH_CHANGE_PASSWORD,
  PATH_EDIT_PROFILE,
  PATH_EDIT_SEND_WHATSAPP_ALERT,
  PATH_EDIT_SEND_WHATSAPP_OPPORTUNITY,
  PATH_USERS,
  PATH_USER_AVAIALBLE,
} from '@constants/api/user';
import { ENTRIES_PER_PAGE } from '@constants/settings/pagination';
import { request } from '@services/.';
import { KipResponse } from '@ts/api/KipResponse';
import { PaginatedData } from '@ts/api/PaginatedData';
import { GetUserResponse, UserAvailableRequest } from '@ts/api/user';
import { User } from '@ts/entities/User';
import { ChangePasswordFormSchema } from '@ts/forms/user/ChangePassword';
import { CreateUserFormSchema } from '@ts/forms/user/CreateUser';
import { EditProfileFormSchema } from '@ts/forms/user/EditProfile';
import { EditUserFormSchema } from '@ts/forms/user/EditUser';

export class UserService {
  static async userAvailable(
    params: UserAvailableRequest,
    excludeIds?: Array<number>
  ): Promise<KipResponse> {
    return request.get(PATH_USER_AVAIALBLE, {
      params: { ...params, excludeIds },
    });
  }

  static async sendOwnerWhatsappAlert(send: boolean) {
    console.log('send', send);
    const data = {
      send,
    };
    return request.put(`${PATH_EDIT_SEND_WHATSAPP_ALERT}`, data);
  }

  static async sendOwnerWhatsappOpportunity(send: boolean) {
    console.log('send', send);
    const data = {
      send,
    };
    return request.put(`${PATH_EDIT_SEND_WHATSAPP_OPPORTUNITY}`, data);
  }

  static async editProfile(
    data: EditProfileFormSchema
  ): Promise<KipResponse<User>> {
    return request.put(`${PATH_EDIT_PROFILE}/${data.id}`, data);
  }

  static async changePassword(
    data: ChangePasswordFormSchema
  ): Promise<KipResponse> {
    return request.post(PATH_CHANGE_PASSWORD, data);
  }

  static async getAll(
    page: number = 1,
    params?: any
  ): Promise<KipResponse<PaginatedData<User>>> {
    return request.get(PATH_USERS, {
      params: {
        paginationStart: ENTRIES_PER_PAGE * (page - 1),
        paginationNumber: ENTRIES_PER_PAGE,
        ...params,
      },
    });
  }

  static async getUser(id: number): Promise<GetUserResponse> {
    return request.get(`${PATH_USERS}/${id}`);
  }

  static async createUser(data: CreateUserFormSchema): Promise<KipResponse> {
    return request.post(`${PATH_USERS}`, data);
  }

  static async editUser(
    id: number,
    data: EditUserFormSchema
  ): Promise<KipResponse> {
    return request.put(`${PATH_USERS}/${id}`, data);
  }

  static async deleteUser(id: number): Promise<KipResponse> {
    return request.delete(`${PATH_USERS}/${id}`);
  }
}
