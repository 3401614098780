import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { message } from 'antd';
import { NpsService } from '@services/nps';
import { SetState } from '@ts/etc/React';
import { NpsFormSchema } from '@ts/forms/nps/npsFormSchema';
import { log } from '@utils/KipLog';
import {
  NPS_PROMPT_MAX_INTERVAL_IN_DAYS,
  NPS_PROMPT_MAX_INTERVAL_IN_MINUTES,
} from '@constants/settings/npsPromptMaxInterval';

type NpsHookData = {
  promptNps: boolean;
  setPromptNps: SetState<boolean>;
  handleNpsFinish: (data: NpsFormSchema) => Promise<void>;
  handleNpsCancel: () => void;
  npsAlreadyPrompted: () => boolean;
  storeLastNpsPromptDate: () => void;
  removeLastNpsPrompDate: () => void;
  shouldPromptNpsAgain: () => boolean;
};

export const useNps = (): NpsHookData => {
  const [promptNps, setPromptNps] = useState(false);

  const handleNpsFinish = useCallback(
    async (data: NpsFormSchema): Promise<void> => {
      message.success('Agradecemos o seu feedback!');
      setPromptNps(false);
      const res = await NpsService.sendFeedback(data);
      if (!res?.success) {
        log.error('NPS feedback sending failed', { data, res });
      }
    },
    []
  );

  const removeReminderLaterLocalStorage = () => {
    window.localStorage.removeItem('lastNpsReminder');
  };

  const shouldPromptNpsAgain = (): boolean => {
    const lastNpsPrompt = window.localStorage.getItem('lastNpsReminder');

    if (!lastNpsPrompt) {
      return false;
    }

    const lastNpsPromptDate = dayjs(lastNpsPrompt);
    const now = dayjs();

    const result =
      now.diff(lastNpsPromptDate, 'day', true) <
      NPS_PROMPT_MAX_INTERVAL_IN_DAYS;
    if (!result) {
      removeReminderLaterLocalStorage();
    }
    return result;
  };

  const npsAlreadyPrompted = (): boolean => {
    const lastNpsPrompt = window.localStorage.getItem('lastNpsPrompt');

    if (!lastNpsPrompt) {
      return false;
    }

    const lastNpsPromptDate = dayjs(lastNpsPrompt);
    const now = dayjs();

    return (
      now.diff(lastNpsPromptDate, 'minute', true) <
      NPS_PROMPT_MAX_INTERVAL_IN_MINUTES
    );
  };

  const storeLastNpsPromptDate = () =>
    window.localStorage.setItem('lastNpsPrompt', dayjs().toISOString());

  const removeLastNpsPrompDate = () =>
    window.localStorage.removeItem('lastNpsPrompt');

  const handleNpsCancel = () => {
    setPromptNps(false);
  };

  const data: NpsHookData = {
    promptNps,
    setPromptNps,
    handleNpsFinish,
    handleNpsCancel,
    npsAlreadyPrompted,
    storeLastNpsPromptDate,
    removeLastNpsPrompDate,
    shouldPromptNpsAgain,
  };

  return data;
};
