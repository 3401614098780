import { Button } from 'antd';
import { useSidebar } from '@contexts/Sidebar';
import { FilePdfOutlined } from '@ant-design/icons';
import { DEFAULT_FREEMIUM_BILLING_UPLOAD_LIMIT } from '@constants/defaults/etc';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import style from './styles.module.less';

export const FreemiumBillingCounter: React.FC = () => {
  const {
    freemiumBillingCount,
    freemiumBillingCountLoading,
    fetchFreemiumBillingCount,
  } = useSidebar();

  const router = useRouter();

  useEffect(() => {
    fetchFreemiumBillingCount();
  }, [router.asPath]);

  const goToBillingVisualization = () => {
    router.push('/faturas/visualizar-faturas');
  };

  if (!freemiumBillingCount) {
    return <></>;
  }

  return (
    <div className={style.billingCountMainWrapper}>
      <Button
        style={{ color: freemiumBillingCountLoading ? '#8c8c8c' : undefined }}
        type="link"
        loading={freemiumBillingCountLoading}
        onClick={goToBillingVisualization}
        icon={<FilePdfOutlined />}
      >
        {freemiumBillingCount}/{DEFAULT_FREEMIUM_BILLING_UPLOAD_LIMIT}
      </Button>
    </div>
  );
};
