import { branchesToDataNodes } from '@components/Inputs/MetersFilter/SelectMeters/utils';
import { formatRangePickerValuesByScale } from '@components/Inputs/MetersFilter/RangePicker/utils';
import { MeterService } from '@services/meter';
import {
  KipDataNode,
  MeterTreeFilterOutput,
  KipMeterTreeData,
} from '@ts/components/MeterTreeData';
import { KipScale } from '@ts/enums/KipScale';
import { SetState } from '@ts/etc/React';
import { RangePickerInput } from '@ts/inputs/RangePickerInput';
import { getIntervalByScale } from '@utils/date/getIntervalByScale';
import { log } from '@utils/KipLog';
import { KipNotification } from '@utils/KipNotification';
import { Dayjs } from 'dayjs';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from './Auth';

export interface MetersFilterContextProps {
  scale: KipScale | null;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  branchIds: Array<number>;
  groupingIds: Array<number>;
  meterIds: Array<number>;
  checkedKeys: Array<string>;
  companyId: number | undefined;
  treeData: Array<KipDataNode>;
  treeOpen: boolean;
  treeLoading: boolean;
  setScale: SetState<KipScale | null>;
  setStartDate: SetState<Dayjs | null>;
  setEndDate: SetState<Dayjs | null>;
  setPreviewDate: SetState<RangePickerInput>;
  setBranchIds: SetState<Array<number>>;
  setGroupingIds: SetState<Array<number>>;
  setMeterIds: SetState<Array<number>>;
  setCheckedKeys: SetState<Array<string>>;
  setCompanyId: SetState<number | undefined>;
  setTreeData: SetState<Array<KipDataNode>>;
  setTreeOpen: SetState<boolean>;
  previewDate: RangePickerInput;
  shouldShowTimePicker: boolean;
  shouldShowDayPicker: boolean;
  output: MeterTreeFilterOutput | null;
}

export const MeterContext = createContext({} as MetersFilterContextProps);

export const useMetersFilter = () => useContext(MeterContext);

export const MetersFilterProvider: React.FC = ({ children }) => {
  // RangePicker
  const [scale, setScale] = useState<KipScale | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [previewDate, setPreviewDate] = useState<RangePickerInput | null>(null);

  // MetersTree
  const [branchIds, setBranchIds] = useState<Array<number>>([]);
  const [groupingIds, setGroupingIds] = useState<Array<number>>([]);
  const [meterIds, setMeterIds] = useState<Array<number>>([]);
  const [checkedKeys, setCheckedKeys] = useState<Array<string>>([]);
  const [companyId, setCompanyId] = useState<number>();
  const [treeData, setTreeData] = useState<Array<KipDataNode>>([]);
  const [treeLoading, setTreeLoading] = useState<boolean>(false);
  const [treeOpen, setTreeOpen] = useState<boolean>(false);

  const { isLoggedIn } = useAuth();

  // Output
  const [output, setOutput] = useState<MeterTreeFilterOutput | null>(null);

  const updateTreeData = async () => {
    if (!companyId) return;
    setTreeLoading(true);
    const res = await MeterService.getListFromCompanyIdWithBranches(companyId);
    if (!res?.data) {
      KipNotification.defaultError();
      setTreeData([]);
      setTreeLoading(false);
      log.warn('updateTreeData request failed', {
        isLoggedIn,
        res,
      });
      return;
    }

    // Filtering branches without meters or groupings
    const dataWithoutEmptyBranches: KipMeterTreeData = res?.data.items.filter(
      (b) => (b.eggs?.length ?? 0) > 0 || (b.groupings?.length ?? 0) > 0
    ) as unknown as KipMeterTreeData;

    setTreeData(branchesToDataNodes(dataWithoutEmptyBranches));
    setTreeLoading(false);
  };

  const resetSelection = (): void => {
    setBranchIds([]);
    setGroupingIds([]);
    setMeterIds([]);
    setCheckedKeys([]);
  };

  useEffect(() => {
    if (isLoggedIn) {
      updateTreeData();
      resetSelection();
    }
  }, [companyId]);

  useEffect(() => {
    if (
      scale &&
      startDate &&
      endDate &&
      (branchIds.length > 0 || groupingIds.length > 0 || meterIds.length > 0)
    ) {
      setOutput({
        scale,
        startDate,
        endDate,
        branchIds,
        groupingIds,
        meterIds,
      });
    } else {
      setOutput(null);
    }
  }, [scale, startDate, endDate, branchIds, groupingIds, meterIds]);

  const shouldShowTimePicker: boolean = useMemo(
    () => scale === KipScale.fifteenMinutes || scale === KipScale.oneHour,
    [scale]
  );

  const shouldShowDayPicker: boolean = useMemo(
    () => scale !== KipScale.oneMonth,
    [scale]
  );

  useEffect(() => {
    if (scale) {
      const interval = getIntervalByScale(scale) as [Dayjs, Dayjs];
      const formatedInterval = formatRangePickerValuesByScale(interval, scale);
      setPreviewDate(formatedInterval);
      setStartDate(formatedInterval[0]);
      setEndDate(formatedInterval[1]);
    }
  }, [scale]);

  const data: MetersFilterContextProps = {
    branchIds,
    groupingIds,
    meterIds,
    setBranchIds,
    setGroupingIds,
    setMeterIds,
    scale,
    setScale,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    previewDate,
    setPreviewDate,
    shouldShowTimePicker,
    shouldShowDayPicker,
    checkedKeys,
    setCheckedKeys,
    companyId,
    setCompanyId,
    treeData,
    setTreeData,
    treeLoading,
    output,
    treeOpen,
    setTreeOpen,
  };

  return <MeterContext.Provider value={data}>{children}</MeterContext.Provider>;
};
