import { RouteGuardian } from '@components/Layout/RouteGuardian';
import { KipLayout } from '@components/Layout/KipLayout';
import { AuthProvider } from '@contexts/Auth';
import type { AppProps } from 'next/app';
import { SidebarProvider } from '@contexts/Sidebar';
import { ConfigProvider } from '@contexts/Config';
import { MetersFilterProvider } from '@contexts/MetersFilter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => (
  <QueryClientProvider client={queryClient}>
    <ConfigProvider>
      <AuthProvider>
        <SidebarProvider>
          <RouteGuardian>
            <KipLayout>
              <MetersFilterProvider>
                <Component {...pageProps} />
              </MetersFilterProvider>
            </KipLayout>
          </RouteGuardian>
        </SidebarProvider>
      </AuthProvider>
    </ConfigProvider>
  </QueryClientProvider>
);

// eslint-disable-next-line import/no-default-export
export default MyApp;
