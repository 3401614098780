export const ROUTE_LOGIN = '/login';
export const ROUTE_FEDERATED_LOGIN_CALLBACK = '/logincb';
export const ROUTE_HOME = '/';
export const ROUTE_COMPANIES = '/empresas';
export const ROUTE_CONSUMPTION = '/analises/consumo';
export const ROUTE_DEMAND = '/analises/demanda';
export const ROUTE_POWER_FACTOR = '/analises/fator-de-potencia';
export const ROUTE_DISTRIBUTION = '/analises/rateio';
export const ROUTE_WORKING_HOUR = '/analises/horario-produtivo';
export const ROUTE_PERIODS = '/analises/postos-tarifarios';
export const ROUTE_METERS = '/medidores';
export const ROUTE_HISTORY = '/historico';
export const ROUTE_BRANCHES = '/unidades';
export const ROUTE_OPPORTUNITYS = '/oportunidades';
export const ROUTE_STOCK_ACTIONS = '/actions/acompanhamento';
export const ROUTE_STOCK_REGISTER = '/actions/register';
export const ROUTE_BILLING_UPLOAD = '/faturas/enviar-faturas';
export const ROUTE_BILLING_RELATION = '/faturas/relacionar-faturas';
export const ROUTE_BILLING_VIEW = '/faturas/visualizar-faturas';
export const ROUTE_BILLING_COLLECTION = '/faturas/coleta';
export const ROUTE_DOC = '/doc';
export const ROUTE_ALERTS = '/alertas';
export const ROUTE_BILLING_CONFIGURATION = '/faturas/configuracao-faturas';

export const ROUTE_USERS = '/usuarios';
export const ROUTE_EXPORTING = '/exportacao';

export const ROUTE_EMBEDDED_PAGES = '/avancado';
export const ROUTE_EMBEDDED_PAGES_CONFIGURATION =
  '/avancado/configuracao-avancado';

export const ROUTE_ORDER_YOUR_DASHBOARD = '/peca-seu-painel';

export const ROUTE_CHANGE_PASSWORD = '/mudar-senha';

export const PUBLIC_ROUTES = [
  ROUTE_LOGIN,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_FEDERATED_LOGIN_CALLBACK,
];
export const PRIVATE_ROUTES = [
  ROUTE_HOME,
  ROUTE_COMPANIES,
  ROUTE_DEMAND,
  ROUTE_POWER_FACTOR,
  ROUTE_WORKING_HOUR,
  ROUTE_PERIODS,
  ROUTE_METERS,
  ROUTE_BRANCHES,
  ROUTE_USERS,
  ROUTE_EXPORTING,
  ROUTE_CONSUMPTION,
  ROUTE_DISTRIBUTION,
  ROUTE_OPPORTUNITYS,
  ROUTE_EMBEDDED_PAGES,
  ROUTE_ORDER_YOUR_DASHBOARD,
  ROUTE_BILLING_UPLOAD,
  ROUTE_BILLING_VIEW,
  ROUTE_ALERTS,
  ROUTE_BILLING_CONFIGURATION,
  ROUTE_STOCK_ACTIONS,
  ROUTE_STOCK_REGISTER,
  ROUTE_EMBEDDED_PAGES_CONFIGURATION,
  ROUTE_HISTORY,
];

export const ALL_ROUTES = [...PUBLIC_ROUTES, ...PRIVATE_ROUTES];

export const SUB_ROUTE_COMPANY_GENERAL_INFO = 'informacoes-gerais';
export const SUB_ROUTE_COMPANY_METERS = 'medidores';
export const SUB_ROUTE_COMPANY_AUTOMATION_BILLING_COLLECTION ='coleta-automatizada'
export const COMPANY_DETAILS_SUB_ROUTES = [
  SUB_ROUTE_COMPANY_GENERAL_INFO,
  SUB_ROUTE_COMPANY_METERS,
];

export const SUB_ROUTE_BRANCH_GENERAL_INFO = 'informacoes-gerais';
export const SUB_ROUTE_BRANCH_DISTRIBUTION_COMPANY =
  'configuracoes-da-distribuidora';
export const SUB_ROUTE_BILLING_COLLECTION_CREDENTIAL = 'coleta-de-faturas';
export const SUB_ROUTE_BRANCH_METERS = 'medidores';
export const SUB_ROUTE_BRANCH_WORKING_HOURS =
  'quadros-de-horario-de-funcionamento';
export const SUB_ROUTE_BRANCH_USERS = 'usuarios-responsaveis';
export const SUB_ROUTE_BRANCH_FARES = 'cadastro-de-tarifas';
export const SUB_ROUTE_BRANCH_BILLINGS = 'faturas';

export const BRANCH_DETAILS_SUB_ROUTES = [
  SUB_ROUTE_BRANCH_GENERAL_INFO,
  SUB_ROUTE_BRANCH_DISTRIBUTION_COMPANY,
  SUB_ROUTE_BILLING_COLLECTION_CREDENTIAL,
  SUB_ROUTE_BRANCH_METERS,
  SUB_ROUTE_BRANCH_WORKING_HOURS,
  SUB_ROUTE_BRANCH_USERS,
  SUB_ROUTE_BRANCH_FARES,
  SUB_ROUTE_BRANCH_BILLINGS,
];
