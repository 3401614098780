import { PATH_NPS_FEEDBACK } from '@constants/api/nps';
import { KipResponse } from '@ts/api/KipResponse';
import { NpsFormSchema } from '@ts/forms/nps/npsFormSchema';
import { request } from '.';

export class NpsService {
  static async sendFeedback(data: NpsFormSchema): Promise<KipResponse> {
    return request.post(PATH_NPS_FEEDBACK, data);
  }
}
