import { PATH_LOGIN } from '@constants/api/auth';
import { PATH_CHANGE_PASSWORD } from '@constants/api/user';
import {
  FullKipNotificationConfig,
  KipNotificationType,
} from '@ts/utils/KipNotification';
import { KipNotification } from '@utils/KipNotification';
import { AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { error, info, success, warn } = KipNotificationType;

type KipErrorHandler = {
  status: number;
  url: string;
  notification?: FullKipNotificationConfig;
};

// Add new handlers here
// NOTE this handler list is meant to work only for responses
// with statuses out of 2xx range. Request failures in 2xx range
// (e.g. KipResponse { success: false }) will not get here and
// should be handled in the moment of request
const errorHandlerList: Array<KipErrorHandler> = [
  {
    url: PATH_LOGIN,
    status: 400,
    notification: {
      type: error,
      title: 'Credenciais incorretas.',
      content: 'Confira suas credenciais e tente novamente.',
    },
  },
  {
    url: PATH_CHANGE_PASSWORD,
    status: 403,
    notification: {
      type: error,
      title: 'Senha incorreta.',
      content: 'Verifique sua senha e tente novamente.',
    },
  },
];

const generateFallbackHandler = (res: AxiosResponse): KipErrorHandler => ({
  status: res.status,
  url: res.config.url ?? '',
  notification: {
    type: error,
    title: 'Ops!',
    content: `Algo deu errado. Por favor, tente novamente. [${res.status}]`,
  },
});

const getErrorHandlerFromResponse = (res: AxiosResponse): KipErrorHandler => {
  const resUrl = res.config.url;
  const resStatus = res.status;
  const errorHandler = errorHandlerList.find(
    (handler) => handler.url === resUrl && handler.status === resStatus
  );
  return errorHandler ?? generateFallbackHandler(res);
};

export const handleRequestError = (res: AxiosResponse): void => {
  const handler: KipErrorHandler = getErrorHandlerFromResponse(res);
  if (handler.notification) {
    KipNotification[handler.notification.type](handler.notification);
  }
  // Open for extension...
};
