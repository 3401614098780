import { KipCurrentUser } from '@ts/entities/User';
import {
  DEFAULT_SIDEBAR_ITEMS,
  getFreemiumSidebarItems,
} from '@constants/defaults/sidebarItems';

import { SidebarItemData, SubmenuItemData } from '@ts/layout/sidebar';
import { EmbeddedPageData, EMBEDS_DATA_KEY } from '@ts/entities/Company';
import React from 'react';
import {
  RetweetOutlined,
  SettingOutlined,
  StarOutlined,
} from '@ant-design/icons';
import {
  ROUTE_EMBEDDED_PAGES,
  ROUTE_EMBEDDED_PAGES_CONFIGURATION,
  ROUTE_ORDER_YOUR_DASHBOARD,
} from '@constants/routes';
import { KipRole } from '@ts/enums/KipRole';

const embeddedPageDataToSidebarItems = (
  epdArr: Array<EmbeddedPageData>,
  startingId: number,
  nodeRedItems?: boolean
) =>
  epdArr.map(
    (epd: EmbeddedPageData, idx: number): SidebarItemData => ({
      id: startingId + idx,
      icon: React.createElement(RetweetOutlined),
      title: epd.displayName,
      to: `${ROUTE_EMBEDDED_PAGES}?id=${epd.id}${
        nodeRedItems ? `&type=native` : ''
      }`,
    })
  );

const getEmbedsSidebarItemForUser = (
  user: KipCurrentUser,
  startingId: number
) => {
  const { company, nodeRedConfigs } = user;

  const companyHasEmbeds: boolean = Boolean(
    company && company[EMBEDS_DATA_KEY] && company[EMBEDS_DATA_KEY]!.length > 0
  );

  const configSubmenuItem: Array<SubmenuItemData> =
    user.role === KipRole.root
      ? [
          {
            id: startingId + 1,
            icon: React.createElement(SettingOutlined),
            title: 'Configurações',
            to: ROUTE_EMBEDDED_PAGES_CONFIGURATION,
          },
        ]
      : [];

  const companiesLength =
    company && company[EMBEDS_DATA_KEY]
      ? (company[EMBEDS_DATA_KEY]?.length as number)
      : 0;

  const nodeRedStartingId = startingId + 2 + companiesLength;

  const embedItem: SidebarItemData = companyHasEmbeds
    ? {
        id: startingId,
        icon: React.createElement(StarOutlined),
        title: 'Avançado',
        submenu: [
          ...configSubmenuItem,
          ...embeddedPageDataToSidebarItems(
            company![EMBEDS_DATA_KEY]!,
            startingId + 2
          ),
          ...embeddedPageDataToSidebarItems(
            (nodeRedConfigs as unknown as EmbeddedPageData[]) || [],
            nodeRedStartingId,
            true
          ),
        ],
      }
    : {
        id: startingId,
        icon: React.createElement(StarOutlined),
        title: 'Avançado',
        to: ROUTE_ORDER_YOUR_DASHBOARD,
      };

  return embedItem;
};

export const getSidebarItemsForUser = (
  user: KipCurrentUser
): Array<SidebarItemData> => {
  const { role } = user;

  const premium = user.company?.premium;

  const defaultSidebarItems = premium
    ? DEFAULT_SIDEBAR_ITEMS
    : getFreemiumSidebarItems();

  const items: Array<SidebarItemData> = [];

  const defaultItemsFilteredByRole: Array<SidebarItemData> =
    defaultSidebarItems.filter((_sid: SidebarItemData) => {
      const sid = _sid;
      if (!sid.submenu) {
        return !sid.notAllowedRoles?.includes(role);
      }
      sid.submenu = sid.submenu.filter(
        (subid: SidebarItemData) => !subid.notAllowedRoles?.includes(role)
      );
      return !sid.notAllowedRoles?.includes(role);
    });

  const ids: Array<number> = defaultItemsFilteredByRole.reduce(
    (acc: number[], sid: SidebarItemData) => {
      const subAcc = sid.submenu?.reduce(
        (subAccIn: number[], sub: SubmenuItemData) => [...subAccIn, sub.id],
        []
      );
      return subAcc ? [...acc, sid.id, ...subAcc] : [...acc, sid.id];
    },
    []
  );
  const maxId = Math.max(...ids);
  const embeddedPagesItem: SidebarItemData = getEmbedsSidebarItemForUser(
    user,
    maxId + 1
  );

  items.push(...defaultItemsFilteredByRole);
  items.push(embeddedPagesItem);

  return items;
};
