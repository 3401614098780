import { request } from '@services/.';
import {
  PATH_FEDERATED_LOGIN,
  PATH_GET_TOKEN_INFO,
  PATH_LOGIN,
  PATH_LOGOUT,
  PATH_ME,
  PATH_RESET_PASS,
  PATH_RESET_PASS_CHANGE,
} from '@constants/api/auth';
import {
  FederatedLoginRequest,
  LoginRequest,
  LoginResponse,
} from '@ts/api/auth';
import { KipResponse } from '@ts/api/KipResponse';

export class AuthService {
  static async login(data: LoginRequest): Promise<LoginResponse> {
    return request.post(PATH_LOGIN, data);
  }

  static async federatedLogin(data: FederatedLoginRequest): Promise<any> {
    return request.post(PATH_FEDERATED_LOGIN, data);
  }

  static async logout(): Promise<KipResponse> {
    return request.get(PATH_LOGOUT);
  }

  static async getMe(): Promise<LoginResponse> {
    return request.get(PATH_ME);
  }

  static async forgotPassword(email: string): Promise<KipResponse> {
    return request.post(PATH_RESET_PASS, { email });
  }

  static async getTokenInfo(
    token: string
  ): Promise<KipResponse<{ username: string }>> {
    return request.get(PATH_GET_TOKEN_INFO, { params: { token } });
  }

  static async passwordRecovery(
    token: string,
    password: string
  ): Promise<KipResponse> {
    return request.post(PATH_RESET_PASS_CHANGE, {
      token: encodeURIComponent(token),
      password,
    });
  }
}
