import { useSidebar } from '@contexts/Sidebar';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { useAuth } from '@contexts/Auth';
import { Menu, Dropdown, Button, Tag } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { EditProfileForm } from '@components/Forms/User/Profile/EditProfileForm';
import { useState } from 'react';
import { ChangePasswordForm } from '@components/Forms/User/Profile/ChangePasswordForm';
import { KipAlertNotification } from '@components/Layout/KipAlertNotification';
import { FreemiumModal } from '@components/Layout/FreemiumPopup';
import {
  TOPBAR_FREEMIUM_TAG_MESSAGE,
  TOPBAR_FREEMIUM_TAG_TITLE,
} from '@constants/defaults/freemiumModal';
import { HintVideoButton } from '@components/Layout/HintVideoButton';
import { FreemiumBillingCounter } from '@components/Layout/FreemiumBillingCounter';
import styles from './styles.module.less';

export const KipTopbar = () => {
  const { collapsed, setCollapsed } = useSidebar();
  const { user, logout, isPremium } = useAuth();

  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const openEditProfileModal = () => setEditProfileModalOpen(true);
  const closeEditProfileModal = () => setEditProfileModalOpen(false);

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const openChangePasswordModal = () => setChangePasswordModalOpen(true);
  const closeChangePasswordModal = () => setChangePasswordModalOpen(false);

  const [freemiumModalOpen, setFreemiumModalOpen] = useState(false);
  const openFreemiumModal = () => setFreemiumModalOpen(true);
  const closeFreemiumModal = () => setFreemiumModalOpen(false);

  const DropdownMenu = (
    <Menu>
      <Menu.Item key="0" onClick={openEditProfileModal}>
        Editar perfil
      </Menu.Item>
      <Menu.Item key="1" onClick={openChangePasswordModal}>
        Alterar senha
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={() => logout()}>
        Sair
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.container}>
      {collapsed ? (
        <MenuUnfoldOutlined
          className={styles.trigger}
          onClick={() => setCollapsed(false)}
        />
      ) : (
        <MenuFoldOutlined
          className={styles.trigger}
          onClick={() => setCollapsed(true)}
        />
      )}

      <div className={styles.actionsButtonContainer}>
        {!isPremium ? <FreemiumBillingCounter /> : null}
        <HintVideoButton />
        {isPremium ? <KipAlertNotification /> : null}
        {!isPremium ? (
          <Tag
            color="green"
            onClick={openFreemiumModal}
            style={{ cursor: 'pointer' }}
          >
            VERSÃO GRATUITA
          </Tag>
        ) : null}
        <Dropdown overlay={DropdownMenu} trigger={['click']}>
          <Button className={styles.userDropdown} type="link">
            {user.name} <DownOutlined />
          </Button>
        </Dropdown>
        <Modal
          title="Editar perfil"
          centered
          visible={editProfileModalOpen}
          footer={null}
          onCancel={closeEditProfileModal}
          destroyOnClose
        >
          <EditProfileForm
            onCancel={closeEditProfileModal}
            afterFinish={closeEditProfileModal}
          />
        </Modal>
        <Modal
          title="Alterar senha"
          centered
          visible={changePasswordModalOpen}
          footer={null}
          onCancel={closeChangePasswordModal}
          destroyOnClose
        >
          <ChangePasswordForm
            onCancel={closeChangePasswordModal}
            afterFinish={closeChangePasswordModal}
          />
        </Modal>
        <FreemiumModal
          title={TOPBAR_FREEMIUM_TAG_TITLE}
          message={TOPBAR_FREEMIUM_TAG_MESSAGE}
          onCancel={closeFreemiumModal}
          visible={freemiumModalOpen}
        />
      </div>
    </div>
  );
};
