export enum KipNotificationType {
  success = 'success',
  error = 'error',
  info = 'info',
  warn = 'warn',
}

export type KipNotificationConfig = {
  title: string;
  content?: string;
  persistent?: boolean;
};

export interface FullKipNotificationConfig extends KipNotificationConfig {
  type: KipNotificationType;
}
