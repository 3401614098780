import { BillingTypes } from '@ts/enums/BillingTypes';

export const PATH_BILLING_SOURCE = '/api/v2/billingSource';
export const PATH_BILLING_OTHERS_MAP = '/api/v2/billingMapping/others';
export const PATH_BILLING_OTHERS_MAP_AFTERMAPPING: string =
  '/api/v2/billingMapping/others/afterMapping';
export const PATH_BILLING_MAPPING_TABLE = '/api/v2/billingMapping/table';
export const PATH_BILLING_SOURCE_METRICS = '/api/v2/billingSourceMetrics';
export const PATH_BILLING_JSON_ID = '/api/v2/billingJson';
export const PATH_BILLING_MAPPING_TABLE_AFTERMAPPING =
  '/api/v2/billingMapping/tableAfterMapping';
export const PATH_BILLING_SOURCE_COUNT = '/api/v2/billingSourceCount';

export const BILLING_PATHS = {
  [BillingTypes.ENERGY]: {
    DEFAULT: '/api/v2/billing',
    PARSE: '/api/v2/billingParse',
    INFO: '/api/v2/billingInfo',
    RESTORE: '/api/v2/billingRestore',
  },
  [BillingTypes.WATER]: {
    DEFAULT: '/api/v2/billingWater',
    PARSE: '/api/v2/billingWaterParse',
    INFO: '/api/v2/billingWater',
    RELATE: '/api/v2/billingWaterRelate',
    RESTORE: '/api/v2/billingWaterRestore',
  },
  [BillingTypes.DANFE]: {
    DEFAULT: '/api/v2/billingDanfe',
    PARSE: '/api/v2/billingDanfeParse',
    INFO: '/api/v2/billingDanfe',
    RELATE: '/api/v2/billingDanfeRelate',
    RESTORE: '/api/v2/billingDanfeRestore',
  },
};
