import { PATH_HINT_VIDEO } from '@constants/api/hintVideo';
import { KipResponse } from '@ts/api/KipResponse';
import { HintVideo } from '@ts/entities/HintVideo';
import { request } from '.';

export class HintVideoService {
  static async getAllVideos(): Promise<KipResponse<HintVideo[]>> {
    return request.get(PATH_HINT_VIDEO);
  }
}
