import { TelephoneNumberInput } from '@components/Inputs/Numeric/TelephoneNumberInput';
import { useAuth } from '@contexts/Auth';
import { useProfileForms } from '@hooks/useProfileForms';
import { EditProfileFormSchema } from '@ts/forms/user/EditProfile';
import { Button, Col, Form, Input, Row } from 'antd';
import { FC, useState } from 'react';
import { emailRules, nameRules, phoneRules, positionRules } from './rules';

const FormItem = Form.Item;

interface EditProfileFormProps {
  onCancel?: Function;
  afterFinish?: Function;
}

export const EditProfileForm: FC<EditProfileFormProps> = ({
  onCancel,
  afterFinish,
}: EditProfileFormProps) => {
  const { user } = useAuth();
  const initialValues: EditProfileFormSchema = {
    id: user.id,
    name: user.name,
    email: user.email,
    phone: user.phone,
    position: user.position,
  };

  const [form] = Form.useForm<EditProfileFormSchema>();
  const [submitLoading, setSubmitLoading] = useState(false);

  const { handleEditProfileFinish } = useProfileForms();

  const handleFinish = async (values: EditProfileFormSchema) => {
    setSubmitLoading(true);
    await handleEditProfileFinish(values);
    if (afterFinish) await afterFinish(values);
    setSubmitLoading(false);
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  return (
    <Form
      name="EditProfile"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={handleFinish}
    >
      <FormItem name="id" style={{ display: 'none' }}>
        <Input disabled />
      </FormItem>
      <FormItem label="Nome Completo" name="name" rules={nameRules}>
        <Input autoComplete="my-user-name" placeholder="Nome Completo" />
      </FormItem>
      <FormItem
        label="E-mail"
        name="email"
        rules={emailRules}
        validateTrigger="onBlur"
      >
        <Input placeholder="nome@exemplo.com" autoComplete="off" />
      </FormItem>
      <FormItem label="Telefone" name="phone" rules={phoneRules}>
        <TelephoneNumberInput
          autoComplete="branch-phone"
          placeholder="Número de contato"
        />
      </FormItem>
      <FormItem label="Cargo" name="position" rules={positionRules}>
        <Input placeholder="Analista" autoComplete="my-user-position" />
      </FormItem>
      <Row justify="end" gutter={20}>
        <Col>
          <Button onClick={() => handleCancel()}>Cancelar</Button>
        </Col>
        <Col>
          <Button loading={submitLoading} htmlType="submit" type="primary">
            Editar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
