import { KipScale } from '@ts/enums/KipScale';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import { Dayjs } from 'dayjs';

export type KipMeterTreeMeter = {
  className?: KipMeterTreeEntityName.egg;
  name: string;
  id: number;
  pending?: boolean;
};

export type KipMeterTreeGrouping = {
  className: KipMeterTreeEntityName.grouping;
  name: string;
  eggs: Array<KipMeterTreeMeter>;
  groupings: Array<KipMeterTreeGrouping>;
  id: number;
};

export type KipMeterTreeBranch = {
  className: KipMeterTreeEntityName.branch;
  tradename: string;
  eggs: Array<KipMeterTreeMeter>;
  groupings: Array<KipMeterTreeGrouping>;
  id: number;
};

export enum KipMeterTreeEntityName {
  branch = 'branch',
  grouping = 'grouping',
  egg = 'egg',
}

export type KipMeterTreeGenericEntity =
  | KipMeterTreeMeter
  | KipMeterTreeGrouping
  | KipMeterTreeBranch;

export type KipDataNodeKey = string;

export type KipDataNode = DataNode & {
  key: KipDataNodeKey;
  type: KipMeterTreeEntityName | string;
  id: number;
  children?: Array<KipDataNode>;
  icon?: any;
};

export type KipMeterTreeData = Array<KipMeterTreeBranch>;

export type KipEventDataNode = EventDataNode & KipDataNode;

export type KipCheckInfo = {
  node: KipEventDataNode;
  checked: boolean;
};

export type MeterTreeFilterOutput = {
  scale: KipScale;
  startDate: Dayjs;
  endDate: Dayjs;
  branchIds: Array<number>;
  groupingIds: Array<number>;
  meterIds: Array<number>;
};
