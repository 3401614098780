import { useSidebar } from '@contexts/Sidebar';
import { SidebarItemData } from '@ts/layout/sidebar';
import { Alert, Image, Menu } from 'antd';
import { useRouter } from 'next/router';
import { getSidebarSelectedKeys } from '@utils/next/getSidebarSelectedKeys';
import { FC, useEffect, useState } from 'react';
import { useAuth } from '@contexts/Auth';
import Modal from 'antd/lib/modal/Modal';
import { CompanyLogoForm } from '@components/Forms/Company/Logo';
import { UploadOutlined } from '@ant-design/icons';
import { CompanyService } from '@services/company';
import { KipNotification } from '@utils/KipNotification';
import { log } from '@utils/KipLog';
import { KipRole } from '@ts/enums/KipRole';
import {
  CUBI_LOGO_IMG_URI,
  CUBI_LOGO_WHITE_IMG_URI,
} from '@constants/routes/assets';
import { DEFAULT_LOGO_UPDATE_ALERT } from '@constants/defaults/logoUpdate';
import {
  FreemiumModal,
  FreemiumModalProps,
} from '@components/Layout/FreemiumPopup';
import {
  DEFAULT_FREEMIUM_MODAL_METERS_PAGE_MESSAGE,
  DEFAULT_FREEMIUM_MODAL_TITLE,
} from '@constants/defaults/freemiumModal';
import { DEFAULT_FREEMIUM_BANNERS } from '@constants/defaults/freemiumBanners';
import { BugReport } from '@components/Layout/BugReport';
import { Key } from 'antd/lib/table/interface';
import { SidebarItem } from './SidebarItem';
import styles from './styles.module.less';
import { EnvTags } from './EnvTags';

const SUBMENUS_PATHNAME: { [key: string]: string } = {
  analises: '2',
  faturas: '13',
  oportunidades: '20',
  avancado: '23',
};

const SUBMENUS_PATHNAME_FREEMIUM: { [key: string]: string } = {
  faturas: '4',
  oportunidades: '9',
  avancado: '11',
};

export const KipSidebar: FC = () => {
  const router = useRouter();
  const path = router.pathname.split('/')[1];

  const { items, collapsed } = useSidebar();
  const { user, updateUser, backendEnv, backendTag, frontendVersion } =
    useAuth();

  const companyPhoto: string | null = user?.company?.photo ?? null;
  const { role } = user;

  const [selectedKeys, setSelectedKeys] = useState([] as string[]);
  const [openKeys, setOpenKeys] = useState<Key[]>([]);
  const [freemiumModalData, setFreemiumModalData] =
    useState<FreemiumModalProps>({
      title: '',
      message: '',
      visible: false,
    });

  const [uploadLogoFormIsOpen, setUploadLogoFormIsOpen] = useState(false);
  const openUploadLogoForm = () => setUploadLogoFormIsOpen(true);
  const closeUploadLogoForm = () => setUploadLogoFormIsOpen(false);

  const handleFreemiumAccesses = (): void => {
    const freemiumBannerIndex = localStorage.getItem('freemiumBannerIndex');

    if (!freemiumBannerIndex) {
      localStorage.setItem('freemiumBannerIndex', '0');
    }

    const freemiumAccesses = localStorage.getItem('freemiumAccesses');

    if (!freemiumAccesses) {
      localStorage.setItem('freemiumAccesses', '0');
      return;
    }

    const freemiumAccessesNumber = parseInt(freemiumAccesses, 10);
    const freemiumBannerIndexNumber = parseInt(
      freemiumBannerIndex as string,
      10
    );

    if (freemiumAccessesNumber + 1 >= 5 && freemiumBannerIndex) {
      const { title, image } =
        DEFAULT_FREEMIUM_BANNERS[freemiumBannerIndexNumber];

      const index =
        freemiumBannerIndexNumber + 1 > DEFAULT_FREEMIUM_BANNERS.length - 1
          ? 0
          : freemiumBannerIndexNumber + 1;

      setFreemiumModalData({
        title,
        banner: image,
        visible: true,
        message: '',
      });
      localStorage.setItem('freemiumBannerIndex', `${index}`);
    }
    localStorage.setItem('freemiumAccesses', `${freemiumAccessesNumber + 1}`);
  };

  useEffect(() => {
    if (user.company?.premium) {
      return;
    }
    handleFreemiumAccesses();
  }, []);

  const handleLogoUploadFinish = async (base64: string) => {
    if (!base64) {
      log.error('handleLogoUploadFinish received invalid data', {
        base64,
      });
      KipNotification.defaultError();
      return;
    }

    const res =
      user.company && user.company.id && user.role === KipRole.root
        ? await CompanyService.updateGeneralInfo({
            id: user.company.id,
            photo: base64,
          })
        : await CompanyService.updatePhoto(base64);
    if (!res?.success) {
      log.error('Failed to update company logo', { res, base64 });
      return;
    }
    await updateUser();
    KipNotification.success({ title: 'Logo atualizada com sucesso!' });
    closeUploadLogoForm();
  };

  useEffect(() => {
    setSelectedKeys(getSidebarSelectedKeys(router.asPath, items));
  }, [router.asPath, items]);

  const onOpenChange = (_openKeys: Key[]) => {
    setOpenKeys([..._openKeys]);
  };

  useEffect(() => {
    if (user.company?.premium) {
      setOpenKeys([SUBMENUS_PATHNAME[path]]);
      return;
    }
    setOpenKeys([SUBMENUS_PATHNAME_FREEMIUM[path]]);
  }, [path]);

  // this method was created to handle the freemium modal
  const onClickMenu = (menuInfo: any): void => {
    if (user.company?.premium) {
      return;
    }

    if (menuInfo.key === '1') {
      setFreemiumModalData({
        title: DEFAULT_FREEMIUM_MODAL_TITLE,
        message: DEFAULT_FREEMIUM_MODAL_METERS_PAGE_MESSAGE,
        visible: true,
      });
    }
  };

  const onCloseModal = (): void => {
    setFreemiumModalData({
      title: '',
      message: '',
      visible: false,
    });
  };

  return (
    <>
      <div className={styles.logoContainer}>
        <img
          src={companyPhoto ?? CUBI_LOGO_IMG_URI}
          alt="logo"
          className={styles.logo}
        />
        {(role === KipRole.admin || role === KipRole.root) && (
          <button
            type="button"
            onClick={openUploadLogoForm}
            className={styles.uploadLogoButton}
          >
            <UploadOutlined />
            {!collapsed ? <p>Alterar Logo</p> : null}
          </button>
        )}
      </div>
      <div className={styles.sidebarContent}>
        <div>
          <Menu
            theme="dark"
            selectedKeys={selectedKeys}
            defaultSelectedKeys={['1']}
            openKeys={openKeys as string[]}
            mode="inline"
            onClick={onClickMenu}
            onOpenChange={onOpenChange}
          >
            {items.map((i: SidebarItemData) => (
              <SidebarItem data={i} key={i.id} />
            ))}
          </Menu>
          {role === KipRole.root && (
            <EnvTags
              frontend={frontendVersion}
              backend={backendTag}
              env={backendEnv}
              collapsed={collapsed}
            />
          )}
        </div>
        <div>
          {role === KipRole.root && backendEnv === '[DEV]' && (
            <BugReport collapsed={collapsed} />
          )}
          <div className={styles.cubiLogoContainer}>
            <Image
              src={CUBI_LOGO_WHITE_IMG_URI}
              alt="cubi-logo"
              className={styles.cubiLogo}
              preview={false}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Alterar Logo"
        footer={null}
        onCancel={closeUploadLogoForm}
        destroyOnClose
        visible={uploadLogoFormIsOpen}
        width={600}
      >
        <Alert
          message={DEFAULT_LOGO_UPDATE_ALERT}
          className={styles.statusMessageAlert}
          type="info"
          closable
        />
        <CompanyLogoForm
          onCancel={closeUploadLogoForm}
          onFinish={handleLogoUploadFinish}
        />
      </Modal>
      <FreemiumModal
        onCancel={onCloseModal}
        title={freemiumModalData.title}
        message={freemiumModalData.message}
        visible={freemiumModalData.visible}
        banner={freemiumModalData.banner}
      />
    </>
  );
};
