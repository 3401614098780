export const DEFAULT_FREEMIUM_MODAL_TITLE =
  'Você descobriu uma funcionalidade premium!';

export const DEFAULT_FREEMIUM_MODAL_METERS_PAGE_MESSAGE =
  'Para ter acesso à plataforma de telemetria em tempo real e alertas você precisa contratar um dos nossos planos! Entre em contato com nossa equipe de especialistas que poderão te ajudar';

export const TOPBAR_FREEMIUM_TAG_TITLE = 'Atualize seu plano!';

export const TOPBAR_FREEMIUM_TAG_MESSAGE =
  'Você está usando uma versão gratuita com funcionalidades limitadas de nosso software. Conheça mais sobre nossa versão completa!';
