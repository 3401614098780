import dayjs, { Dayjs } from 'dayjs';
import {
  ONE_DAY,
  MIN_MAX_BY_FILTER,
  ONE_MONTH,
  RAW_DATA,
  FIFTEEN_MINUTES,
  ONE_HOUR,
  MAX_BY_FILTER_EXPORT,
  MILLISECONDS_IN_HOUR,
} from '@constants/components/metersFilter';
import { KipScale } from '@ts/enums/KipScale';
import { RangePickerValues } from '@ts/inputs/RangePickerInput';

const nextDayOnly = (date: Dayjs | null, dayValue: Dayjs): boolean => {
  const myDate = dayjs(date);
  const myDayValue = dayjs(dayValue);

  if (myDate === null || !myDayValue) {
    return false;
  }
  return (
    Math.abs(
      dayjs
        .duration(
          myDate
            .clone()
            .hour(0)
            .minute(0)
            .second(0)
            .diff(myDayValue.clone().hour(0).minute(0).second(0))
        )
        .asDays()
    ) >= 2
  );
};

const disabledDatesForHour = (date: Dayjs | null, dayValue: Dayjs): boolean => {
  const myDate = dayjs(date);
  const myDayValue = dayjs(dayValue);

  if (myDate === null || !myDayValue) {
    return false;
  }
  return (
    Math.abs(
      dayjs
        .duration(
          myDate
            .clone()
            .hour(0)
            .minute(0)
            .second(0)
            .diff(myDayValue.clone().hour(0).minute(0).second(0))
        )
        .asDays()
    ) >= 2
  );
};

const disabledDatesForDay = (date: Dayjs | null, dayValue: Dayjs): boolean => {
  const myDate = dayjs(date);
  const myDayValue = dayjs(dayValue);

  if (myDate === null) {
    return false;
  }

  return (
    Math.abs(
      dayjs
        .duration(
          myDate
            .hour(0)
            .minute(0)
            .second(0)
            .diff(myDayValue.hour(0).minute(0).second(0))
        )
        .asDays()
    ) >= MIN_MAX_BY_FILTER[ONE_DAY].maxByDates
  );
};

const disabledDatesForMonth = (
  date: Dayjs | null,
  dayValue: Dayjs
): boolean => {
  if (date === null || !dayValue) {
    return false;
  }

  const mydate = dayjs(date);
  const myDayValue = dayjs(dayValue);

  const previewDateNoDaysNoTime = mydate
    .clone()
    .date(1)
    .minute(0)
    .hour(0)
    .second(0);
  const currentDateNoDaysNoTime = myDayValue
    .clone()
    .date(1)
    .minute(0)
    .hour(0)
    .second(0);
  const diffInMonths = dayjs
    .duration(currentDateNoDaysNoTime.diff(previewDateNoDaysNoTime))
    .asMonths();

  if (Math.abs(diffInMonths) > 24) {
    return true;
  }

  if (mydate.isSame(dayValue)) {
    return true;
  }

  const isDifferentMonthYear = !currentDateNoDaysNoTime.isSame(
    previewDateNoDaysNoTime
  );
  if (isDifferentMonthYear) {
    if (mydate.date() > myDayValue.clone().endOf('month').date()) {
      return myDayValue.date() !== myDayValue.clone().endOf('month').date();
    }
    return mydate.date() !== myDayValue.date();
  }
  return true;
};

const isScaleOf = (value: number, scale: number): boolean =>
  value % scale === 0;

const roundMinutesToScale = (
  dates: RangePickerValues,
  scaleInMinutes: number
): RangePickerValues => {
  if (!dates) return dates;
  let initDate = dates[0];
  let endDate = dates[1];
  if (!!initDate && !isScaleOf(initDate.minute(), scaleInMinutes)) {
    initDate = initDate.minute(0);
  }
  if (!!endDate && !isScaleOf(endDate.minute(), scaleInMinutes)) {
    endDate = endDate.minute(0);
  }
  const diffMinutes = dayjs.duration(endDate?.diff(initDate) ?? 0).asMinutes();
  if (diffMinutes < scaleInMinutes) {
    endDate = dayjs(initDate).add(scaleInMinutes, 'm');
  }
  return [initDate, endDate];
};

const handleDateLimit = (
  newValues: RangePickerValues,
  maxSelectionTime: number
): RangePickerValues => {
  const [startDate, endDate] = newValues;
  const limitDate: Dayjs = dayjs(startDate).add(maxSelectionTime, 'hour');

  return [startDate, endDate && endDate > limitDate ? limitDate : endDate];
};

const formatRangePickerValuesByScale = (
  values: RangePickerValues,
  scale: KipScale,
  isExport?: boolean
): RangePickerValues => {
  let limit = 0;
  let newValues: RangePickerValues = [
    values[0]?.second(0) ?? null,
    values[1]?.second(0) ?? null,
  ];

  if (isExport) {
    const maxSelectionRange = MAX_BY_FILTER_EXPORT[scale];

    newValues = handleDateLimit(newValues, maxSelectionRange * 24);

    return newValues;
  }

  if (scale === FIFTEEN_MINUTES) {
    newValues = roundMinutesToScale(newValues, 15);
    limit = 6;
  }
  if (scale === ONE_HOUR) {
    if (!!newValues && newValues.length > 0) {
      newValues = roundMinutesToScale(newValues, 30);
      const startDate = newValues[0];
      let endDate = newValues[1];
      const diffHours = dayjs.duration(endDate?.diff(startDate) ?? 0).asHours();
      if (diffHours < 1) {
        endDate = dayjs(startDate).add(1, 'h');
      }
      if (diffHours > MIN_MAX_BY_FILTER[scale].maxByHours) {
        endDate = dayjs(startDate).add(
          MIN_MAX_BY_FILTER[scale].maxByHours,
          'h'
        );
      }
      return [startDate, endDate];
    }
  }
  if (scale === ONE_DAY || scale === ONE_MONTH) {
    const [startDate] = newValues;
    const value = scale === ONE_DAY ? 34 : 23;
    const unit = scale === ONE_DAY ? 'days' : 'months';
    limit =
      dayjs(startDate).add(value, unit).diff(dayjs(startDate)) /
      MILLISECONDS_IN_HOUR;
  }

  newValues = handleDateLimit(newValues, limit);
  if (scale === ONE_DAY || scale === ONE_MONTH || scale === RAW_DATA) {
    return [
      newValues[0]?.minute(0).hour(0) ?? null,
      newValues[1]?.minute(0).hour(0) ?? null,
    ];
  }
  return newValues;
};

export {
  isScaleOf,
  roundMinutesToScale as formatDatesForMinutesScales,
  disabledDatesForHour,
  disabledDatesForDay,
  disabledDatesForMonth,
  nextDayOnly,
  formatRangePickerValuesByScale,
};
