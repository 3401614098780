import { KipScale } from '@ts/enums/KipScale';
import dayjs from 'dayjs';

export const FIFTEEN_MINUTES = KipScale.fifteenMinutes;
export const ONE_HOUR = KipScale.oneHour;
export const ONE_DAY = KipScale.oneDay;
export const ONE_MONTH = KipScale.oneMonth;
export const RAW_DATA = KipScale.rawData;

export const FIFTEEN_MINUTES_DESCRIPTION = 'Limite de 6 horas de seleção';
export const THIRTY_MINUTES_DESCRIPTION = 'Limite de 12 horas de seleção';
export const ONE_HOUR_DESCRIPTION = 'Limite de 24 horas de seleção';
export const ONE_DAY_DESCRIPTION = 'Limite de 35 dias de seleção';
export const ONE_WEEK_DESCRIPTION = 'Limite de 26 semanas de seleção';
export const ONE_MONTH_DESCRIPTION = 'Limite de 24 meses de seleção';
export const RAW_DATA_DESCRIPTION = '';

export const ONE_HOUR_INTERVAL = 'hora(s)';
export const ONE_DAY_INTERVAL = 'dia(s)';
export const ONE_WEEK_INTERVAL = 'semana(s)';
export const ONE_MONTH_INTERVAL = 'mês/meses';

export const DEFAULT_SCALE_VISUALIZATION_OPTIONS = [
  { label: '15 Min', value: FIFTEEN_MINUTES },
  { label: '1 Hora', value: ONE_HOUR },
  { label: '1 Dia', value: ONE_DAY },
  { label: '1 Mês', value: ONE_MONTH },
];

export const EXPORT_SCALE_VISUALIZATION_OPTIONS = [
  { label: '15 Min', value: FIFTEEN_MINUTES },
  { label: '1 Hora', value: ONE_HOUR },
  { label: '1 Dia', value: ONE_DAY },
  { label: '1 Mês', value: ONE_MONTH },
];

export const DEFAULT_SCALE_VISUALIZATION_DESCRIPTIONS = [
  FIFTEEN_MINUTES,
  ONE_HOUR,
  ONE_DAY,
  ONE_MONTH,
];

export const DATE_FORMAT_PTBR_WITH_TIME = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_PTBR = 'DD/MM/YYYY';
export const DATE_FORMAT_PTBR_WITHOUT_DAY = 'MM/YYYY';

export const MIN_MAX_BY_FILTER = {
  [FIFTEEN_MINUTES]: {
    maxByHours: 6,
  },
  [ONE_HOUR]: {
    minByHours: 1,
    maxByHours: 24,
  },
  [ONE_DAY]: {
    maxByDates: 35,
  },
  [ONE_MONTH]: {
    maxByWeeks: 96,
  },
};

const FIVE_YEARS_IN_DAYS = 1820;

export const MAX_BY_FILTER_EXPORT: Record<KipScale, number> = {
  [FIFTEEN_MINUTES]: 37,
  [ONE_HOUR]: FIVE_YEARS_IN_DAYS,
  [ONE_DAY]: FIVE_YEARS_IN_DAYS,
  [ONE_MONTH]: FIVE_YEARS_IN_DAYS,
  [RAW_DATA]: FIVE_YEARS_IN_DAYS,
};

export const TIME_OPTIONS = {
  [FIFTEEN_MINUTES]: {
    defaultValue: dayjs().minute(0),
    format: 'HH:mm',
    minuteStep: 15,
  },
  [ONE_HOUR]: {
    defaultValue: dayjs().minute(0),
    format: 'HH:mm',
    minuteStep: 30,
  },
};

export const MILLISECONDS_IN_HOUR = 3600000;

export const DATE_OPTIONS = {
  [FIFTEEN_MINUTES]: {
    format: DATE_FORMAT_PTBR_WITH_TIME,
    minuteStep: 15,
  },
  [ONE_HOUR]: {
    format: DATE_FORMAT_PTBR_WITH_TIME,
  },
  [ONE_DAY]: {
    disabledDate: true,
    format: DATE_FORMAT_PTBR,
  },
  [ONE_MONTH]: {
    disabledDate: true,
    format: DATE_FORMAT_PTBR,
  },
  [RAW_DATA]: {
    format: DATE_FORMAT_PTBR,
  },
};

export const ITEM_LIMIT = 21;
export const ITEM_LIMIT_WARN = `Limite de seleção de ${ITEM_LIMIT} itens atingido`;
