import React from 'react';
import Script from 'next/script';

export const GoogleAnalytics = () => {
  const MEASUREMENT_ID = process.env.NEXT_PUBLIC_ANALYTICS_ID;

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID}`}
      />

      <Script id="" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${MEASUREMENT_ID}');
        `}
      </Script>
    </>
  );
};
