import {
  PATH_ALERTS_LOG,
  PATH_ALERT_CONFIG,
  PATH_ALERT_CONFIG_CHANGE_STATUS,
  PATH_ALERT_NOTIFICATION,
} from '@constants/api/alerts';
import {
  AlertConfigListResponse,
  AlertConfigResponse,
  AlertLogListResponse,
  AlertNotificationListResponse,
  DeleteAlertNotificationResponse,
  NewAlertConfig,
} from '@ts/api/alerts';
import { EditAlertFormSchema } from '@ts/forms/alert';
import { ENTRIES_PER_PAGE } from '@constants/settings/pagination';
import { request } from '.';

export class AlertService {
  static async getAlertsLog(
    params: any,
    page: number = 1
  ): Promise<AlertLogListResponse> {
    return request.get(PATH_ALERTS_LOG, {
      params: {
        paginationStart: ENTRIES_PER_PAGE * (page - 1),
        paginationNumber: ENTRIES_PER_PAGE,
        ...params,
      },
    });
  }

  static async deleteAlertLog(id: number): Promise<AlertLogListResponse> {
    return request.delete(`${PATH_ALERTS_LOG}/${id}`);
  }

  static async getAlertsConfigurations(
    params: any,
    page: number = 1
  ): Promise<AlertConfigListResponse> {
    return request.get(PATH_ALERT_CONFIG, {
      params: {
        paginationStart: ENTRIES_PER_PAGE * (page - 1),
        paginationNumber: ENTRIES_PER_PAGE,
        ...params,
      },
    });
  }

  static async getAlertConfigurationById(
    id: number
  ): Promise<AlertConfigResponse> {
    return request.get(`${PATH_ALERT_CONFIG}/${id}`);
  }

  static async updateAlertConfiguration(
    id: number,
    payload: EditAlertFormSchema
  ): Promise<AlertConfigResponse> {
    return request.put(`${PATH_ALERT_CONFIG}/${id}`, payload);
  }

  static async deleteAlertConfiguration(
    id: number
  ): Promise<AlertConfigResponse> {
    return request.delete(`${PATH_ALERT_CONFIG}/${id}`);
  }

  static async pauseAlert(id: number): Promise<AlertConfigResponse> {
    return request.put(`${PATH_ALERT_CONFIG_CHANGE_STATUS}/${id}`);
  }

  static async resumeAlert(id: number): Promise<AlertConfigResponse> {
    return request.put(`${PATH_ALERT_CONFIG_CHANGE_STATUS}/${id}`);
  }

  static async createAlert(
    newAlert: NewAlertConfig
  ): Promise<AlertConfigResponse> {
    return request.post(PATH_ALERT_CONFIG, newAlert);
  }

  static async getKipNotifications(
    args: any
  ): Promise<AlertNotificationListResponse> {
    return request.get(PATH_ALERT_NOTIFICATION, {
      params: {
        ...args,
      },
    });
  }

  static async updateKipNotification(
    ids: number[]
  ): Promise<DeleteAlertNotificationResponse> {
    return request.put(PATH_ALERT_NOTIFICATION, {
      ids,
    });
  }

  static async deleteKipNotification(
    ids: number[]
  ): Promise<DeleteAlertNotificationResponse> {
    return request.delete(PATH_ALERT_NOTIFICATION, {
      params: {
        ids,
      },
    });
  }
}
