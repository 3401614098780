import {
  ROUTE_FEDERATED_LOGIN_CALLBACK,
  ROUTE_HOME,
  ROUTE_LOGIN,
} from '@constants/routes';
import { useAuth } from '@contexts/Auth';
import { log } from '@utils/KipLog';
import { isRoutePublic } from '@utils/next/isRoutePublic';
import { isServer } from '@utils/next/isServer';
import { NextPage } from 'next';
import { useRouter } from 'next/router';

export const RouteGuardian: NextPage = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const router = useRouter();
  const routeIsPublic = isRoutePublic(router.asPath);
  const shouldBeRedirected = isLoggedIn === routeIsPublic;
  const redirectTo = isLoggedIn ? ROUTE_HOME : ROUTE_LOGIN;

  if (router.asPath.includes(ROUTE_FEDERATED_LOGIN_CALLBACK)) {
    return <>{children}</>;
  }

  if (shouldBeRedirected) {
    log.info(`RouteGuardian redirecting to ${redirectTo}`, {
      isLoggedIn,
      routeIsPublic,
      shouldBeRedirected,
      redirectTo,
    });
    if (!isServer()) router.push(redirectTo);
    return <></>;
  }

  return <>{children}</>;
};
