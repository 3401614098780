import { Badge, Button, Popover, Typography } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useSidebar } from '@contexts/Sidebar';
import { DEFAULT_NOTIFICATION_REFETCH_INTERVAL_IN_MS } from '@constants/defaults/alerts';
import { useQuery } from '@tanstack/react-query';
import { AlertList } from './AlertList';
import styles from './styles.module.less';

const { Link } = Typography;

export const KipAlertNotification: React.FC = () => {
  const { notifications, fetchNotifications } = useSidebar();

  const { isLoading } = useQuery(['notifications'], fetchNotifications, {
    refetchInterval: DEFAULT_NOTIFICATION_REFETCH_INTERVAL_IN_MS,
  });

  const contentPopover = () => (
    <div className={styles.contentPopover}>
      <div className={styles.contentAlerts}>
        <AlertList
          loading={isLoading}
          fetchNotifications={fetchNotifications}
          notifications={notifications}
        />
      </div>
      <div className={styles.seeAllAlerts}>
        <Link href="/alertas">Visualizar todos os alertas</Link>
      </div>
    </div>
  );

  return (
    <Popover
      placement="bottomRight"
      content={contentPopover()}
      title="Alertas Pendentes"
      className={styles.container}
      trigger="click"
    >
      <Badge count={notifications.length} className={styles.notificationBadge}>
        <Button
          type="link"
          icon={<BellOutlined className={styles.bellIcon} />}
        />
      </Badge>
    </Popover>
  );
};
