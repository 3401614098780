/* eslint-disable no-console */
const isDev = process.env.NODE_ENV === 'development';
const devLogDisabled = process.env.NEXT_PUBLIC_DEV_LOG_DISABLED === 'true';
const shouldLogInConsole = () =>
  // @ts-ignore
  (isDev && !devLogDisabled) || window.enableKipLog;

// eslint-disable-next-line @typescript-eslint/naming-convention
export class log {
  static info(message: string, info?: object): void {
    const from = log.getCaller();
    if (shouldLogInConsole()) {
      console.log(
        `\x1b[46m \x1b[107m INFO: ${message}`,
        info ? '\n' : '',
        info ? { from, info } : { from }
      );
    }
  }

  static warn(message: string, info?: object): void {
    const from = log.getCaller();
    if (shouldLogInConsole()) {
      console.log(
        `\x1b[43m \x1b[107m WARN: ${message}`,
        info ? '\n' : '',
        info ? { from, info } : { from }
      );
    }
  }

  static error(message: string, info?: object): void {
    const from = log.getCaller();
    if (shouldLogInConsole()) {
      console.log(
        `\x1b[41m \x1b[107m ERROR: ${message}`,
        info ? '\n' : '',
        info ? { from, info } : { from }
      );
    }
  }

  static getCaller(level: number = 3) {
    return new Error().stack
      ?.split('\n')
      [level]?.split('///')[1]
      ?.split(')')[0];
  }
}
