import { request } from '@services/.';
import { AuthService } from '@services/auth';
import { LoginRequest } from '@ts/api/auth';
import { KipCurrentUser, LoginResponseUser } from '@ts/entities/User';
import { SetState } from '@ts/etc/React';
import { formatBackendTag } from '@utils/formatters/formatBackendTag';
import { formatUser } from '@utils/formatters/formatUser';
import { formatBackendEnv } from '@utils/formatters/formatBackendEnv';
import { createTokenInserter } from '@utils/interceptors/createTokenInserter';
import { createUnauthorizedListener } from '@utils/interceptors/createUnauthorizedListener';
import { log } from '@utils/KipLog';
import { KipNotification } from '@utils/KipNotification';
import {
  useEffect,
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { VERSION } from '@constants/settings/version';
import { useNps } from '@hooks/useNps';
import { NpsFormSchema } from '@ts/forms/nps/npsFormSchema';
import { KipRole } from '@ts/enums/KipRole';
import * as Sentry from '@sentry/nextjs';

export interface AuthContextProps {
  user: KipCurrentUser;
  isLoggedIn: boolean;
  login: (data: LoginRequest) => Promise<boolean>;
  logout: () => Promise<boolean>;
  token: string;
  setUser: SetState<KipCurrentUser>;
  backendTag: string;
  frontendVersion: string;
  backendEnv: string;
  updateUser: () => Promise<void>;
  promptNps: boolean;
  handleNpsFinish: (data: NpsFormSchema) => Promise<void>;
  handleNpsCancel: () => void;
  roleIsUser: boolean;
  setLoggedUserInfo: (data: LoginResponseUser) => void;
  isPremium: boolean;
}

export const AuthContext = createContext({} as AuthContextProps);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState({} as KipCurrentUser);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);

  const [backendTag, setBackendTag] = useState('');
  const [frontendVersion, setFrontendVersion] = useState('');
  const [backendEnv, setBackendEnv] = useState('');

  const {
    promptNps,
    handleNpsFinish,
    handleNpsCancel,
    setPromptNps,
    npsAlreadyPrompted,
    storeLastNpsPromptDate,
    removeLastNpsPrompDate,
    shouldPromptNpsAgain,
  } = useNps();

  const isLoggedIn = useMemo(() => !!user.id, [user]);

  const [authInterceptors, setAuthInterceptors] = useState(
    {} as { tokenInserter: number; unauthorizedListener: number }
  );

  const removeAuthInterceptors = () => {
    request.interceptors.request.eject(authInterceptors.tokenInserter);
    request.interceptors.response.eject(authInterceptors.unauthorizedListener);
  };

  const kickUser = (forced: boolean = false) => {
    log.info('Kicking user', { forced });
    setUser({} as KipCurrentUser);
    removeAuthInterceptors();
    removeLastNpsPrompDate();
    window.localStorage.setItem('token', '');
    if (forced) {
      KipNotification.info({
        title: 'Faça seu login',
        content: 'Por favor, faça seu login novamente.',
      });
    }
  };

  const clearResponseInterceptors = () => {
    const { handlers } = request.interceptors.request as any;
    if (handlers) {
      for (let i = 0; i < handlers.length; i++) {
        if (handlers[i]) {
          request.interceptors.request.eject(i);
        }
      }
    }
  };

  const addAuthInterceptors = (jwtToken: string) => {
    clearResponseInterceptors();
    const tokenInserter = request.interceptors.request.use(
      createTokenInserter(jwtToken)
    );
    const unauthorizedListener = request.interceptors.response.use(
      createUnauthorizedListener(() => kickUser(true))
    );
    setAuthInterceptors({ tokenInserter, unauthorizedListener });
    setToken(jwtToken);
  };

  const updateAuthStates = useCallback(
    (data: LoginResponseUser) => {
      setUser(formatUser(data));
      setBackendTag(formatBackendTag(data.tag));
      setBackendEnv(formatBackendEnv(data.env));
      if (
        data.role !== KipRole.root &&
        !npsAlreadyPrompted() &&
        !shouldPromptNpsAgain()
      ) {
        if (data.promptNps) storeLastNpsPromptDate();
        setPromptNps(data.promptNps);
      }
    },
    [setPromptNps]
  );

  const setLoggedUserInfo = (loggedUser: LoginResponseUser) => {
    updateAuthStates(loggedUser);
    Sentry.setUser({ email: loggedUser.email });

    addAuthInterceptors(loggedUser.token);

    // TODO stop using localStorage and implement JWT best practices
    // including refresh token mechanism (involves backend features)
    window.localStorage.setItem('token', loggedUser.token);
  };

  const login = async (data: LoginRequest): Promise<boolean> => {
    const loginResponse = await AuthService.login(data);

    if (!loginResponse?.success) {
      log.warn('Login failed', { loginResponse });
      return false;
    }

    removeLastNpsPrompDate();
    const resUser = loginResponse?.data;

    setLoggedUserInfo(resUser);

    log.info('Login successful', { resUser });
    return true;
  };

  const logout = async (): Promise<boolean> => {
    const logoutResponse = await AuthService.logout();
    if (!logoutResponse?.success) {
      log.warn('Logout failed', { logoutResponse });
      return false;
    }
    log.info('Logout successful', { logoutResponse });
    removeLastNpsPrompDate();
    kickUser(false);
    Sentry.configureScope((scope) => scope.setUser(null));
    return true;
  };

  const checkTokenValidity = async (): Promise<void> => {
    try {
      removeAuthInterceptors();

      const storedToken = window.localStorage.getItem('token');
      log.info('Revalidating token', { storedToken });
      if (!storedToken) {
        log.warn('No stored token found. Kicking user.', { storedToken });
        throw new Error();
      }

      addAuthInterceptors(storedToken);
      const meResponse = await AuthService.getMe();

      if (!meResponse?.success) {
        log.warn('Unsuccessful response for token validation. Kicking user.', {
          meResponse,
        });
        throw new Error();
      }

      updateAuthStates(meResponse.data);

      setUser(formatUser(meResponse.data));
      setBackendTag(formatBackendTag(meResponse.data.tag));
      setBackendEnv(formatBackendEnv(meResponse.data.env));
      if (
        meResponse.data.role !== KipRole.root &&
        !npsAlreadyPrompted() &&
        !shouldPromptNpsAgain()
      ) {
        if (meResponse.data.promptNps) storeLastNpsPromptDate();
        setPromptNps(meResponse.data.promptNps);
      }
      log.info('Successfully revalidated token.', { meResponse });
    } catch {
      kickUser();
    } finally {
      setLoading(false);
    }
  };

  const updateUser = checkTokenValidity;

  useEffect(() => {
    checkTokenValidity();
    setFrontendVersion(VERSION);
  }, []);

  const roleIsUser = user.role === KipRole.user;
  const isPremium = user?.company?.premium === 1;

  const data: AuthContextProps = {
    user,
    isLoggedIn,
    token,
    login,
    logout,
    setUser,
    frontendVersion,
    backendTag,
    backendEnv,
    updateUser,
    promptNps,
    handleNpsFinish,
    handleNpsCancel,
    roleIsUser,
    setLoggedUserInfo,
    isPremium,
  };

  return (
    <AuthContext.Provider value={data}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
