import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { ALLOWED_IMAGE_TYPES_LIST } from '@constants/components/uploadLogoForm';
import { getBase64 } from '@utils/helpers/getBase64';
import { log } from '@utils/KipLog';
import { Upload, Button, Image, Row } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { FC, useCallback, useState } from 'react';
import styles from './styles.module.less';
import { postCropValidate, preCropValidate } from './utils';

type CompanyLogoFormProps = {
  onFinish?: (base64: string) => void;
  onCancel?: () => void;
};

export const CompanyLogoForm: FC<CompanyLogoFormProps> = ({
  onFinish,
  onCancel,
}) => {
  const [img, setImg] = useState('');

  const onUpload = useCallback(async (info: UploadChangeParam) => {
    const antdFile: UploadFile = info.file;
    const { status } = antdFile;
    const file: File | undefined = antdFile.originFileObj;

    if (status === 'uploading') return;

    if (!file || status === 'error') {
      log.error('Invalid file provided to onUpload', { info, file, status });
      return;
    }

    if (!(await postCropValidate(file))) return;

    if (status === 'done') {
      const base64 = await getBase64(file);
      setImg(base64);
    }
  }, []);

  const handleFinish = useCallback(() => {
    if (onFinish && img) onFinish(img);
  }, [onFinish, img]);

  return (
    <>
      {!img ? (
        <ImgCrop
          aspect={1 / 1}
          grid
          modalTitle="Cortar imagem"
          modalCancel="Cancelar"
          modalOk="Confirmar"
          beforeCrop={preCropValidate}
        >
          <Upload.Dragger
            multiple={false}
            showUploadList={false}
            onChange={onUpload}
            // Overriding default request behavior
            customRequest={({ onSuccess }) => {
              if (onSuccess) onSuccess({}, new XMLHttpRequest());
            }}
            accept={ALLOWED_IMAGE_TYPES_LIST.join(',')}
            className={styles.dndBox}
          >
            <p>Arraste sua imagem aqui</p>
            <p>ou</p>
            <Button className={styles.uploadButton} icon={<UploadOutlined />}>
              Selecionar arquivo...
            </Button>
          </Upload.Dragger>
        </ImgCrop>
      ) : (
        <div>
          <div className={styles.previewImgContainer}>
            <Image
              preview={false}
              className={styles.previewImg}
              src={img || ''}
              alt="uploaded-image"
              height="100%"
              width="100%"
            />
            <div className={styles.imgPreviewOverlay}>
              <button
                className={styles.deleteImgButton}
                type="button"
                onClick={() => setImg('')}
              >
                <DeleteOutlined />
                <p>Remover</p>
              </button>
            </div>
          </div>
          <p className={styles.previewDescription}>
            A área destacada no centro é o que ficará visível quando o menu
            lateral estiver em seu tamanho normal. Com o menu retraído, toda a
            imagem será exibida.
          </p>
        </div>
      )}
      <Row justify="end" style={{ width: '100%', marginTop: 15 }}>
        {onCancel && (
          <Button
            shape="round"
            type="ghost"
            onClick={() => onCancel()}
            style={{ marginRight: 10 }}
          >
            Cancelar
          </Button>
        )}
        <Button
          disabled={!img}
          onClick={handleFinish}
          shape="round"
          type="primary"
          htmlType="button"
        >
          Salvar
        </Button>
      </Row>
    </>
  );
};
