import { KipCurrentUser, LoginResponseUser } from '@ts/entities/User';

export const formatUser = (user: LoginResponseUser): KipCurrentUser => ({
  id: user.id,
  name: user.name,
  role: user.role,
  email: user.email,
  position: user.position,
  phone: user.phone,
  company: user.company,
  nodeRedConfigs: user.nodeRedConfigs,
});
