import { FC, useState } from 'react';
import { Layout } from 'antd';
import { useSidebar } from '@contexts/Sidebar';
import { useRouter } from 'next/router';
import { shouldIgnoreLayout } from '@utils/next/shouldIngoreLayout';
import { GoogleAnalytics } from '@components/Services/GoogleAnalytics';
import styles from './styles.module.less';
import { KipSidebar } from './KipSidebar';
import { KipTopbar } from './KipTopbar';
import { NpsPrompt } from '../NpsPrompt';
import { HelpModal } from '../Help/HelpModal';
import { HelpButton } from '../Help/HelpButton';

const { Header, Content, Sider } = Layout;

export const KipLayout: FC = ({ children }) => {
  const [helpModalState, setHelpModalState] = useState(false);
  const { collapsed, setCollapsed } = useSidebar();
  const shouldUseAnalytics =
    process.env.NEXT_PUBLIC_SHOULD_USE_ANALYTICS &&
    process.env.NEXT_PUBLIC_SHOULD_USE_ANALYTICS === 'true';
  const router = useRouter();

  const ignoreLayout = shouldIgnoreLayout(router.asPath);

  if (ignoreLayout) return <>{children}</>;

  return (
    <Layout className={styles.layout} hasSider>
      <Sider
        trigger={null}
        width={220}
        breakpoint="sm"
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
      >
        <KipSidebar />
      </Sider>
      <Layout>
        <Header className={styles.header}>
          <KipTopbar />
        </Header>
        {shouldUseAnalytics ? <GoogleAnalytics /> : null}
        <Content>{children}</Content>
        <HelpButton setHelpModalState={setHelpModalState} />
        <NpsPrompt />
        <HelpModal
          modalState={helpModalState}
          onClose={() => setHelpModalState(false)}
        />
      </Layout>
    </Layout>
  );
};
