import { createContext, useContext, useEffect, useState } from 'react';
import { useAlerts } from '@hooks/useAlerts';
import { AlertNotification } from '@ts/api/alerts';
import { SidebarItemData } from '@ts/layout/sidebar';
import { getSidebarItemsForUser } from '@utils/helpers/getSidebarItemsForUser';
import { BillingService } from '@services/billing';
import { useAuth } from './Auth';

export interface SidebarContextProps {
  items: Array<SidebarItemData>;
  setItems: (i: Array<SidebarItemData>) => void;
  collapsed: boolean;
  setCollapsed: (c: boolean) => void;
  notifications: Array<AlertNotification>;
  setNotifications: (i: Array<AlertNotification>) => void;
  fetchNotifications: () => Promise<void>;
  freemiumBillingCount: number | false;
  fetchFreemiumBillingCount: () => Promise<void>;
  freemiumBillingCountLoading: boolean;
}

export const SidebarContext = createContext({} as SidebarContextProps);

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const isRoot = user?.role === 'root';
  const [items, setItems] = useState([] as Array<SidebarItemData>);
  const [collapsed, setCollapsed] = useState(false);
  const [freemiumBillingCount, setFreemiumBillingCount] = useState<
    number | false
  >(false);
  const [freemiumBillingCountLoading, setFreemiumBillingCountLoading] =
    useState<boolean>(true);

  const fetchFreemiumBillingCount = async () => {
    if (!isRoot) {
      setFreemiumBillingCountLoading(true);
      const res = await BillingService.getBillingCount();
      setFreemiumBillingCountLoading(false);
      if (!res || !res.success || !res.data) {
        return setFreemiumBillingCount(false);
      }
      
      return setFreemiumBillingCount(res.data);
    }

  };

  const { fetchAlertNotifications } = useAlerts();

  const [notifications, setNotifications] = useState(
    [] as Array<AlertNotification>
  );

  const fetchNotifications = async (): Promise<void> => {
    const res = await fetchAlertNotifications({
      searchUnread: true,
    });
    if (res) {
      setNotifications(res.items);
    }
  };

  useEffect(() => {
    setItems(getSidebarItemsForUser(user));
  }, [user]);

  const data: SidebarContextProps = {
    items,
    setItems,
    collapsed,
    setCollapsed,
    notifications,
    setNotifications,
    fetchNotifications,
    freemiumBillingCount,
    fetchFreemiumBillingCount,
    freemiumBillingCountLoading,
  };

  return (
    <SidebarContext.Provider value={data}>{children}</SidebarContext.Provider>
  );
};
